import ContainerBlockGenericType from "../ContainersBlocks/ContainerBlockGenericType";

function OUTD
    ({ id, isConnectable, type, selected }) {
    return (
        <ContainerBlockGenericType
            id={id}
            isConnectable={isConnectable}
            type={type}
            selected={selected}
            flagNumber={42}
            adderFlag={22}
            labelBlock={'OUTD'}
            showLabelInput={true}
            LabelInputRight={'43px'}
            userConfiguration={true}
        />
    );
}
export default OUTD