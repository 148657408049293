import Container from "../Elements/Container"
import TopNav from "../common/TopNav"
import React, { useEffect, useState, useContext, useCallback, useRef } from 'react';
import Root from "./Root";
import {
    Button, Box, Grid, CircularProgress, Tooltip, makeStyles, Chip, MenuItem, Select, FormControl, InputLabel
} from '@material-ui/core';
import {
    useNodesState,
    // useEdgesState,
    // useOnSelectionChange
    applyEdgeChanges,
    MarkerType,
    addEdge,
} from 'reactflow';
//Funciones auxiliares.
import { createTree } from "./helpers";
//Hook personalizado.
import DiagramContext from "./Store/DiagramContext";
//Componentes.
import AlertComponent from '../Elements/AlertComponent';
import DeletePanel from "./DeletePanel";
import LoadingComponentBlur from "../common/LoadingComponentBlur.js";
import ConfirmationDialog from "../AlertsAndReports/ConfirmationDialog.js";
//Servicios.
import {
    postSaveDiagramAlert, getIndividualDiagramAlert, deleteDiagramAlert,
    putUpdateDiagramAlert, createDiagram, deleteDiagram, getDiagramByid, editDiagram,
    getExportDiagram, getExportDiagramPlcFormat,
} from "../../services/diagrams";
import {
    getPlc_automate_24v_byId, getExportDiagramAutomate,
    getExportDiagramPlcFormatAutomate, getPlc_automate_24v_list, putDiagramInAutomate, getStateSendDiagram, getDeviceConnectionStatus
} from "../../services/plc_automate.js";
import { useHistory } from 'react-router-dom';
//icons
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import SendAndArchiveIcon from '@mui/icons-material/SendAndArchive';
import CloudOffIcon from '@mui/icons-material/CloudOff';
import TrackChangesIcon from '@mui/icons-material/TrackChanges';
import HourglassFullIcon from '@material-ui/icons/HourglassFull';
import FilterListOffIcon from '@mui/icons-material/FilterListOff';
import ReplyAllIcon from '@mui/icons-material/ReplyAll';
import ModalName from "./ModalName.js";
//Helper
import { validatorEdges } from './helpers.js';
// Custom Hooks
import useDeviceDetect from "../../hooks/useDeviceDetect";
//Socket
// import WebSocketComponent from "./WebSocketComponent.js";

const useStyles = makeStyles(theme => ({
    btn: {
        '& .MuiButton-label': {
            color: theme.palette.common.white,
            textTransform: 'none',
        },
        '&.MuiButton-root': {
            backgroundColor: theme.palette.primary.dark,
        },
    },
    btnDisabled: {
        '& .MuiButton-label': {
            color: '#b8c1c4a3',
            textTransform: 'none',
        },
        '&.MuiButton-root': {
            backgroundColor: 'grey',
        },
    },
    btnFileDownload: {
        width: '100%',
        marginTop: '4px',
        '& .MuiButton-label': {
            color: 'black',
        },
    },
    spinning: {
        animation: `$spin 2s infinite`,
    },
    '@keyframes spin': {
        '0%': { transform: 'rotate(0deg)' },
        '50%': { transform: 'rotate(180deg)' },
        '100%': { transform: 'rotate(360deg)' },
    },
    chip: {
        height: '35px',
        marginLeft: theme.spacing(3),
    },
    formControl: {
        width: '250px',
        height: '63px',
    },
    label: {
        color: 'white',
    },
    select: {
        color: 'white', // Texto del selector
        '&:before': {
            borderBottom: '1px solid white', // Línea inferior por defecto
        },
        '&:hover:not(.Mui-disabled):before': {
            borderBottom: '2px solid white', // Línea inferior al pasar el mouse
        },
        '&:after': {
            borderBottom: '2px solid white', // Línea inferior al seleccionar
        },
    },
    selectIcon: {
        color: 'white', // Color de la flechita
    },
}));

const DynamicDiagram = ({ componentType, params_id, setRefresh }) => {
    const history = useHistory();
    const classes = useStyles();
    const { isMobile } = useDeviceDetect(930);
    const { stateDiagram, setStateDiagram } = useContext(DiagramContext);
    const [loading, setLoading] = useState(true)
    const [loadingSave, setLoadingSave] = useState(false)
    let nodesAux = []
    if (componentType === 'ALERTS_VARIABLES') {
        nodesAux = [{
            id: '0',
            position: { x: 0, y: -10 },
            type: 'ALERT',
        }]
    }
    const [id_diagram, setId_diagram] = useState('');
    const [nodes, setNodes, onNodesChange] = useNodesState(nodesAux);
    // const [edges, setEdges, onEdgesChange] = useEdgesState([]);
    const [edges, setEdges] = useState([]);
    const [initialedges, setInitialEdges] = useState([]);
    const [initialnodes, setInitialNodes] = useState(nodesAux);
    const [initilAlertState, setInitialAlertState] = useState();
    const [selectedNodes, setSelectedNodes] = useState([]);
    // const [selectedEdges, setSelectedEdges] = useState([]);
    // const [diagram, setDiagram] = useState({});
    const [reactFlowInstance, setReactFlowInstance] = useState(null);
    //Estado de la alerta dinamica
    const [close, setClose] = useState(false)
    const [msg, setMsg] = useState({
        msg: "",
        type: "error"
    })
    const [stateNavIcon, setStateNavIcon] = useState({});
    // Estado encargado de abrir el panel de eliminacion de Dashboard.
    const [openDeletePanel, setOpenDeletePanel] = useState(false)
    // Estado encargado de guardar los distintos pasos de el diagrama.
    // const [diagramStates, setDiagramStates] = useState([])
    const fileInputRef = useRef(null);
    const [openSelectFile, setOpenSelectFile] = useState(false);
    //estado q abre el modal de nombre
    const [editName, setEditName] = useState(componentType === 'CREATOR' && !params_id ? true : false)
    const [diagramName, setDiagramName] = useState({ name: '' })
    const [showChipState, setShowChipState] = useState(false)
    const [resetZoom, setResetZoom] = useState(true)
    //Estado del modal delete
    const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState(false);
    const [dataDiagramList, setDataDiagramList] = useState([]);
    const [valueDeviceSelected, setValueDeviceSelected] = useState()

    //Toma los datos de el diagrama ya existente debe estar al final de los useEffect.
    useEffect(() => {
        //Si existe un estado lo cargo
        setStateDiagram({
            ...stateDiagram,
            componentType
        })
        if (componentType === 'ALERTS_VARIABLES') {
            getDataDiagramAlert(params_id)
        }
        else if (componentType === 'Automate-24V-4M') {
            getDataDiagramAutomate_24v(params_id)
            getDeviceConnectionStatusFnt(params_id)
        }
        else if (componentType === 'CREATOR') {
            getDiagramByidFtn(params_id)
            getplcListFtn()
        }
        // eslint-disable-next-line
    }, [params_id])


    //Cierra el modal de el boton
    const containerRef = useRef(null); // Referencia al contenedor que queremos observar
    // Maneja el clic fuera del contenedor
    useEffect(() => {
        function handleClickOutside(event) {
            if (containerRef.current && !containerRef.current.contains(event.target)) {
                setOpenSelectFile(false);
            }
        }
        // Agregar el event listener
        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            // Limpiar el event listener
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);

    useEffect(() => {
        const handleKeyDown = (event) => {
            if (event.ctrlKey && event.key === 'd') {
                // Evita que el navegador abra el diálogo de marcadores
                event.preventDefault();
                // Cambia los colores y pone selected en false de los edges.
                let res = [...edges].map((elem) => {
                    if (elem?.selected === true) {
                        elem.selected = false;
                        elem.style = {
                            ...elem.style,
                            stroke: 'black',
                        };
                        return elem;
                    } else {
                        return elem;
                    }
                });
                setEdges(res);
                // 'El usuario presionó Ctrl + D'
                if (!stateDiagram?.openModal) {
                    onCopyBlock();
                }
            }
        };
        document.addEventListener('keydown', handleKeyDown);
        // Limpia el event listener cuando el componente se desmonte
        return () => {
            document.removeEventListener('keydown', handleKeyDown);
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedNodes, nodes, stateDiagram?.openModal]);


    const handleOpenSelectFileDownload = () => {
        setOpenSelectFile(!openSelectFile)
    }

    const removeDuplicatesById = (array) => {
        const seen = new Map();
        return array.filter(item => {
            if (seen.has(item.id)) {
                // Si el id ya ha sido visto, no incluimos el elemento en el nuevo array
                return false;
            } else {
                // Si el id no ha sido visto, lo añadimos al mapa y al nuevo array
                seen.set(item.id, true);
                return true;
            }
        });
    }

    //Escucha cualquier cambio de los edges.
    const onEdgesChange = useCallback(
        (changes) => {
            const checkSelected = (oldEdges) => {
                let arrayNew = []
                for (let i = 0; i < [...changes].length; i++) {
                    let element = changes[i]
                    let elementFind = [...oldEdges].find((elem) => elem.id === element.id)
                    if (element.selected === true) {
                        elementFind.style = {
                            ...elementFind.style,
                            stroke: '#2586bc',
                        }
                    }
                    else {
                        elementFind.style = {
                            ...elementFind.style,
                            stroke: 'black',
                        }
                    }
                    arrayNew = removeDuplicatesById([...oldEdges, elementFind])
                }
                return arrayNew
            }
            setEdges((oldEdges) => applyEdgeChanges(changes, checkSelected(oldEdges)));
        },
        [setEdges],
    )

    //Escucha cuando comienza la conexión de un Edge. y valoda deshabilitar nodo.
    const onConnectStart = (event) => {
        let idAux = event.target.dataset.nodeid
        let filterNode = nodes.find(e => e.id === idAux)
        let typeNodeSelected = filterNode.type
        let arry = []
        if (typeNodeSelected === 'VARIABLE' || typeNodeSelected === 'CONST') { //si es uno de estos bloques.
            arry = ['AND', 'OR', 'NOT', 'ALERT'] //bloqueo estos bloques.
        }
        if (typeNodeSelected === 'NOT' || typeNodeSelected === 'OR' || typeNodeSelected === 'AND') {
            arry = ['LEQ', 'GEQ', 'EQ', 'LESS', 'GREATER', 'ADDER']
        }
        if (typeNodeSelected === 'LEQ' || typeNodeSelected === 'GEQ' || typeNodeSelected === 'EQ' || typeNodeSelected === 'LESS' || typeNodeSelected === 'GREATER' || typeNodeSelected === 'ADDER') {
            arry = ['LEQ', 'GEQ', 'EQ', 'LESS', 'GREATER', 'ADDER']
        }
        setStateDiagram({
            ...stateDiagram,
            disabledNodes: arry
        })
    }

    // escucha cuando conecta un bloque con otro.
    const onConnect = useCallback(
        (params, nodes) => {
            //Limpia el bloque q tiene error si lo conectas o se conecta el bloque con otro.
            let findErrorBlockTarget = stateDiagram?.errorBlockNodes?.includes(params?.target)
            let findErrorBlockSource = stateDiagram?.errorBlockNodes?.includes(params?.source)
            if (findErrorBlockTarget || findErrorBlockSource) {
                setStateDiagram({
                    ...stateDiagram,
                    errorBlockNodes: []
                })
            }
            //Valida las conexciones entre nodos.
            let res = validatorEdges(params, nodes, edges)
            // params.type = 'step'
            params.markerEnd = {
                type: MarkerType.ArrowClosed,
                width: 15,
                height: 20,
                color: '#1d1c33d1',
            }
            params.style = { strokeWidth: 0.8 }
            if (res === true) {
                setEdges((eds) => {
                    return addEdge(params, eds)
                })
            }
            else {
                setClose(true)
                setMsg({
                    msg: res.msg,
                    type: "error"
                })
            }
            // eslint-disable-next-line
        }, [setEdges, edges, nodes, stateDiagram],);

    //Evento cuando suelta el edge después de comenzar una conexión.
    const onConnectEnd = () => {
        setStateDiagram({
            ...stateDiagram,
            disabledNodes: []
        })
    }

    //Funcion encargada de escuchar el drop de un bloque.
    const onDrop = useCallback(
        (event) => {
            event.preventDefault()
            const type = event.dataTransfer.getData('application/reactflow');
            // check if the dropped element is valid
            if (typeof type === 'undefined' || !type) {
                return;
            }
            const position = reactFlowInstance.screenToFlowPosition({
                x: event.clientX,
                y: event.clientY,
            })
            const lastElement = nodes[nodes?.length - 1];
            let newId = '1'
            if (lastElement) {
                newId = Number(lastElement.id) + 1
            }
            let newNode = {
                id: newId.toString(),
                position: position,
                type: type,
                // data: { value: newId.toString() },
            }
            let nodesAux = [
                ...nodes,
                newNode
            ]

            setNodes(nodesAux)
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [reactFlowInstance, nodes, setNodes,],
    );

    //Funcion q toma los datos existentes de un diagrama alerta.
    const getDataDiagramAlert = async () => {
        try {
            if (params_id) {

                // let res = await getDiagramAlert()
                let res = await getIndividualDiagramAlert(params_id)
                let diagramAlert = res.data
                let alertObject = {
                    alert: diagramAlert
                }
                let nodesAux = diagramAlert.nodes.map((element) => {
                    element.a = transformPropertiesToString(element.a)
                    return element
                })
                setNodes(nodesAux)
                setEdges(diagramAlert.edges)
                setStateDiagram({
                    ...stateDiagram,
                    alertObject
                })
                setInitialNodes(nodesAux)
                setInitialEdges(diagramAlert.edges)
                setInitialAlertState({
                    ...stateDiagram,
                    alertObject
                })
            }
        }
        catch (error) {
            let msg = ''
            if (error?.response?.data?.msg) {
                msg = `${error?.response?.data?.msg}.`
            }
            else {
                msg = `Ocurrió un error inesperado`
            }
            setMsg({
                msg: msg,
                type: "error"
            })
        }
        setClose(false)
        setTimeout(() => {
            setLoading(false)
        }, [1000])
    }

    //AUXILIAR! CAMBIAR HABLAR CON CRIS.
    function transformPropertiesToString(obj) {
        // Verificamos que el argumento pasado es un objeto, pero no null
        if (typeof obj !== 'object' || obj === null || Array.isArray(obj)) {

            return
        }
        // Recorremos todas las propiedades del objeto
        for (let key in obj) {
            // Verificamos que la propiedad es propia del objeto y no heredada
            if (obj.hasOwnProperty(key)) {
                // Convertimos la propiedad a string
                obj[key] = String(obj[key]);
            }
        }
        return obj;
    }
    ///////////////////////////

    //Funcion q toma los datos existentes de un diagrama PLC.
    const getDataDiagramAutomate_24v = async (id) => {
        try {
            if (id) {
                let res = await getPlc_automate_24v_byId(id)
                if (res?.data?.diagram?.nodes) {
                    //Auxiliar transforma los datos de a en formato strng.(va a ser remplazado.)
                    let nodesAux = [...res?.data?.diagram?.nodes].map((element) => {
                        element.a = transformPropertiesToString(element.a)
                        return element
                    })
                    /////////////////////////////////////   
                    // setDiagram(res?.data?.diagram)
                    setNodes(nodesAux)
                    setEdges(res?.data?.diagram?.edges)
                    setInitialNodes(res?.data?.diagram?.nodes)
                    setInitialEdges(res?.data?.diagram?.edges)
                    setId_diagram(res?.data?.diagram?.id)
                    //Agrego estado para las flechas de back y next.
                    // setDiagramStates([
                    //     {
                    //         nodes: res?.data?.diagram?.nodes,
                    //         edges: res?.data?.diagram?.edges
                    //     }
                    // ])
                }
            }
        }
        catch (error) {
            let msg = ''
            if (error?.response?.data?.msg) {
                msg = `${error?.response?.data?.msg}.`
            }
            else {
                msg = `Ocurrió un error inesperado`
            }
            setMsg({
                msg: msg,
                type: "error"
            })
        }
        setClose(false)
        setLoading(false)
    }

    //Funcion q verifica la conexion con el dispositivo.
    const getDeviceConnectionStatusFnt = async (params_id) => {
        try {
            let res = await getDeviceConnectionStatus(params_id)
            console.log('Consultar si el diagrama esta sincronizado con el dispositivo.')
            console.log('Spamm function')
            console.log(res?.data)
        }
        catch (error) {
        }
    }

    //Funcion encargada del drop de los bloques.
    const onCopyBlock = () => {
        let flag = 1
        let newArry = selectedNodes.map((elem) => {
            const lastElement = nodes[nodes.length - 1];
            let newId = Number(lastElement.id) + flag
            flag = flag + 1
            let copy = {
                position: {
                    x: 0,
                    y: 0
                }
            }
            copy.id = newId.toString()
            copy.position.x = elem.position.x + 16
            copy.position.y = elem.position.y + 16
            copy.selected = true
            // Si hago copia de estos bloques anulo el valueToRelative, por q los pines son unicos, reseteo el estado .a
            if (elem.type === 'INA' || elem.type === 'IND' || elem.type === 'OUTD' || elem.type === 'OUTA') {
                copy.a = {}
            }
            return {
                ...elem,
                ...copy,
            }
        })
        let newNodes = nodes.map((elem) => {
            elem.selected = false
            return elem
        })
        let nodesAux = [
            ...newNodes,
            ...newArry
        ]
        setNodes(nodesAux)
    }

    //Escucha todos los eventos q modifiquen nodes, edges.
    const onSelectionDrag = (event) => {
        // setSelectedEdges(event.edges)
        setSelectedNodes(event.nodes)
    };

    //Funcion Auxiliar. Remueve la propiedad id para enviar al back.
    const removeNodePropertyId = (nodo) => {
        // Verificar si el nodo es válido
        if (nodo && typeof nodo === 'object') {
            // Eliminar la propiedad id si existe
            if (nodo.hasOwnProperty('id')) {
                delete nodo.id;
            }
            // Recorrer los hijos del nodo si existen
            if (nodo.hasOwnProperty('childrens') && Array.isArray(nodo.childrens)) {
                nodo.childrens.forEach((hijo) => {
                    removeNodePropertyId(hijo); // Llamar recursivamente a la función para cada hijo
                });
            }
        }
    }

    //Guarda diagrama.
    const save = async () => {
        setLoadingSave(true)
        //Paso todos los nodos a selected false.
        setNodes(nodes.map((elem) => {
            elem.selected = false
            return elem
        }))
        try {
            let tree = createTree(nodes, edges, stateDiagram)
            removeNodePropertyId(tree)
            // Convierte el objeto a una cadena JSON
            var myObj = {
                alert: {
                    ...stateDiagram?.alertObject?.alert,
                    nodes: nodes,
                    edges: edges,
                },
                tree: tree,
            }
            //Pongo en negro los edges de nuevo por q no estan seleccionados
            setEdges(edges.map((elem) => {
                elem.style = {
                    ...elem.style,
                    stroke: 'black'
                }
                return elem
            }))
            if (componentType === 'ALERTS_VARIABLES') {
                //si existe un diagrama lo edito si no lo creo.
                if (params_id) {
                    //edito diagrama
                    await putUpdateDiagramAlert(params_id, myObj)
                }
                else {
                    //creo diagrama
                    let res = await postSaveDiagramAlert(myObj)
                    history.push(`/alert/${res.data.id}`)
                }
            }
            if (componentType === 'CREATOR') {
                let body = {
                    "type_plc": "Automate-24V-4M",
                    name: diagramName.name,
                    edges: edges,
                    nodes: nodes
                }
                //si existe un diagrama lo edito si no lo creo.
                if (params_id) {
                    await editDiagram(params_id, body)
                }
                else {
                    let res = await createDiagram(body)
                    history.push(`/diagram/${res?.data?.id}`)
                    setRefresh(prevRefresh => !prevRefresh);
                }
            }
            setInitialNodes(nodes)
            setInitialEdges(edges)
            setInitialAlertState({ ...stateDiagram?.alertObject })
            setMsg({
                msg: "Se ha enviado su configuración.",
                type: "success"
            })
            //Limpio errores de bloques por q paso el save.
            setStateDiagram({
                ...stateDiagram,
                errorBlockNodes: []
            })
        }
        catch (error) {
            let msg = ''
            if (error?.response?.data?.msg) {
                msg = `${error?.response?.data?.msg}.`
                //Identifico q bloque tiene error
                setStateDiagram({
                    ...stateDiagram,
                    errorBlockNodes: [error?.response?.data?.node_id]
                })
            }
            else {
                msg = `Ocurrió un error inesperado`
            }
            setMsg({
                msg: msg,
                type: "error"
            })
        }
        setLoadingSave(false)
        setClose(true)
    }

    //Carga la lista de todos los plc existentes del user.
    const getplcListFtn = async () => {
        try {
            let res = await getPlc_automate_24v_list()
            if (res?.data) {
                setDataDiagramList(res?.data)
            }
        }
        catch (error) {
            let msg = ''
            if (error?.response?.data?.msg) {
                msg = `${error?.response?.data?.msg}.`
            }
            else {
                msg = `Ocurrió un error inesperado`
            }
            setMsg({
                msg: msg,
                type: "error"
            })
        }
    }

    //Obtengo datos de un diagrama por su id.
    const getDiagramByidFtn = async (idAux) => {
        setLoading(true)
        try {
            if (idAux) {
                let res = await getDiagramByid(idAux)
                let findElement = res?.data
                setDiagramName({
                    name: findElement?.name
                })
                //Transformo todas las propiedades de a:  en string
                let nodesAux = findElement?.nodes.map((element) => {
                    element.a = transformPropertiesToString(element.a)
                    return element
                })
                setNodes(nodesAux)
                setEdges(findElement?.edges)
                setInitialNodes(nodesAux)
                setInitialEdges(findElement?.edges)
            }
        }
        catch (error) {
            let msg = ''
            if (error?.response?.data?.msg) {
                msg = `${error?.response?.data?.msg}.`
            }
            else {
                msg = `Ocurrió un error inesperado`
            }
            setMsg({
                msg: msg,
                type: "error"
            })
        }
        setTimeout(() => {
            setLoading(false)
        }, [400])
        setShowChipState(true)
    }

    //Elimina diagrama
    const deleteFunction = async () => {
        try {
            setLoadingSave(true)
            setNodes([])
            setEdges([])
            setStateDiagram({
                alertObject: {
                    alert: {
                        name: '',
                        frequency: 20,
                        notify: false,
                    },
                    tree: {
                    }
                }
            })
            if (componentType === 'ALERTS_VARIABLES') {
                await deleteDiagramAlert(params_id)
                history.push(`/alerts`)
            }
            if (componentType === 'CREATOR') {
                await deleteDiagram(params_id)
                history.push(`/diagram_list`)
            }
            setMsg({
                msg: "Se elimino su configuración",
                type: "success"
            })
        }
        catch (error) {
            let msg = ''
            if (error.response) {
                msg = `${error.response.data.msg}.`
            }
            else {
                msg = `Ocurrió un error inesperado`
            }
            setMsg({
                msg: msg,
                type: "error"
            })
        }
        setClose(true)
    }

    //Vuelve al estado original de el diagrama.
    const cancel = () => {
        //Pongo en negro los edges de nuevo por q no estan seleccionados
        let edgesAux = initialedges.map((elem) => {
            elem.style = {
                ...elem.style,
                stroke: 'black'
            }
            return elem
        })
        setEdges(edgesAux)
        setNodes(initialnodes)
        setStateDiagram(initilAlertState)
    }

    //Devuelve el icon segun el estado.
    const returnIconState = (element) => {
        if (element.key === 'UNSPECIFIED') {
            return <ErrorOutlineIcon style={{ color: '#3ed73e' }} />
        }
        if (element.key === 'OK') {
            return <CheckCircleOutlineIcon style={{ color: '#3ed73e' }} />
        }
        if (element.key === 'PENDING_LOAD') {
            return <HourglassFullIcon className={classes.spinning} style={{ color: '#FFAA33' }} />
        }
        if (element.key === 'PROCESS_LOAD') {
            return <HourglassFullIcon className={classes.spinning} style={{ color: '#FFAA33' }} />
        }
        if (element.key === 'ERROR_COMUNICATION') {
            return <FilterListOffIcon style={{ color: '#f75200' }} />
        }
        if (element.key === 'ERROR_BLOCKS') {
            return <ErrorOutlineIcon style={{ color: '#f75200' }} />
        }
    }

    //Handle encargado de abrir y cerrar el panel de eliminacion de dashboard.
    const handleOpenCloseDeletePanel = () => {
        setOpenDeletePanel(!openDeletePanel)
    }

    //Deshabilita el boton GUARDAR cuanto el estado sigue en proceso o en pendiente.
    const disabledButtonSave = () => {
        if (stateNavIcon?.key === 'PENDING_LOAD' || stateNavIcon?.key === 'PROCESS_LOAD') {
            return true
        } else {
            return false
        }
    }

    //Descarga typo de formato del diagrama.
    const handleDownloadFile = async (type) => {
        let res = {}
        if (!params_id) {
            setMsg({
                msg: "Debe guardar su diagrama antes de proceder con la exportación.",
                type: "error"
            })
            setClose(true)
            return
        }
        if (id_diagram) {
            if (type === 'plc') {
                res = await getExportDiagramPlcFormatAutomate(params_id, id_diagram)
            }
            else {
                res = await getExportDiagramAutomate(params_id, id_diagram)
            }
        }
        else {
            if (type === 'plc') {
                res = await getExportDiagramPlcFormat(params_id)
            }
            else {
                res = await getExportDiagram(params_id)
            }
        }
        let archivoJson = res?.data;
        try {
            let blob, fileName;
            if (type === 'json') {
                blob = new Blob([JSON.stringify(archivoJson, null, 2)], { type: 'application/json' });
                fileName = 'archivo.pwmgrph';
            }
            else if (type === 'plc') {
                blob = new Blob([JSON.stringify(archivoJson, null, 2)], { type: 'application/jsonl' });
                fileName = 'archivo.jsonl';
            }
            else {
                throw new Error('Tipo de archivo no soportado');
            }
            const url = window.URL.createObjectURL(blob);
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', fileName); // Nombre del archivo con extensión adecuada
            document.body.appendChild(link);
            link.click();
            link.remove();
        }
        catch (error) {
        }
    }

    //Export/import
    const handleFileChange = (event) => {
        setLoadingSave(true)
        const file = event.target.files[0]
        let flagError = false
        if (file) {
            const reader = new FileReader();
            reader.onload = (e) => {
                const content = e.target.result;
                try {
                    const jsonContent = JSON.parse(content);
                    if (jsonContent && jsonContent.length >= 2) {
                        if (jsonContent && Array.isArray(jsonContent[0]) && Array.isArray(jsonContent[1])) {
                            setResetZoom(!resetZoom);
                            setNodes(jsonContent[0]);
                            setEdges(jsonContent[1]);
                        }
                        else {
                            flagError = true
                        }
                    }
                }
                catch (error) {
                    flagError = true
                }
                if (flagError === true) {
                    setLoadingSave(false)
                    setMsg({
                        msg: "Hay un error en su archivo.",
                        type: "error"
                    })
                    setClose(true)
                }
            };
            reader.readAsText(file);
        }
        setTimeout(() => {
            setLoadingSave(false)
        }, [500])
    };

    //Export/import
    const handleUploadFile = () => {
        fileInputRef.current.accept = '.pwmgrph';  // Solo acepta archivos JSON
        fileInputRef.current.value = '';
        fileInputRef.current.click();
    }

    const returnUrlDynamic = () => {
        let res = {
            url: 'plc_list'
        }
        if (componentType === 'ALERTS_VARIABLES') {
            res.url = 'alerts'
        }
        if (componentType === 'CREATOR') {
            res.url = 'diagram_list'
        }
        return res.url
    }

    //Funcion q retorna boton dinamico.
    const returnIconButtonNav = () => {
        return (
            <Box>
                <Box ml={5} display='flex' alignItems='center'>
                    <Box mr={3} >
                        <Tooltip
                            title={`Volver a la lista.`}
                            arrow
                        >
                            <Button
                                style={{ marginLeft: "10px" }}
                                className={classes.btn}
                                type="button"
                                onClick={() => history.push(`/${returnUrlDynamic()}`)}
                                size="small"
                            >   <ReplyAllIcon />
                            </Button>
                        </Tooltip>
                        {showChipState && componentType === 'CREATOR' &&
                            <>
                                <Chip
                                    className={classes.chip}
                                    label={diagramName?.name?.length > 0 ? diagramName?.name : 'Ingrese un nombre'}
                                    clickable
                                    onClick={() => setEditName(true)}
                                />
                            </>
                        }
                    </Box>
                </Box>
            </Box >
        )
    }


    //Estado del boton realtime
    const [stateRealTimeButton, setStateRealTimeButton] = useState(false)
    const [realTimeButtonDisabled, setRealTimeButtonDisabled] = useState(true)

    function areArraysEqual(array1, array2) {
        // Verifica si tienen la misma longitud
        if (array1.length !== array2.length) {
            return false;
        }
        // Compara cada objeto en el mismo índice
        for (let i = 0; i < array1.length; i++) {
            if (!deepCompare(array1[i], array2[i])) {
                return false; // Si algún objeto no coincide, devuelve false
            }
        }
        return true; // Si todos los objetos coinciden, los arrays son iguales
    }

    function deepCompare(value1, value2) {
        // Compara valores estrictamente
        if (value1 === value2) return true;
        // Comprueba si ambos son objetos y no nulos
        if (
            typeof value1 !== "object" ||
            typeof value2 !== "object" ||
            value1 === null ||
            value2 === null
        ) {
            return false;
        }
        // Obtiene claves de ambos objetos
        const keys1 = Object.keys(value1);
        const keys2 = Object.keys(value2);
        // Verifica si tienen la misma cantidad de claves
        if (keys1.length !== keys2.length) return false;
        // Compara cada clave recursivamente
        for (let key of keys1) {
            if (!keys2.includes(key) || !deepCompare(value1[key], value2[key])) {
                return false;
            }
        }
        return true; // Si todas las claves y valores coinciden
    }

    //Carga el estado del diagrama del plc seleccionado para compararlo con el actual.
    const getplcCompareDiagram = async (params_id) => {
        let res = await getPlc_automate_24v_byId(params_id)
        if (res?.data?.diagram) {
            console.log(areArraysEqual(res?.data?.diagram?.nodes, nodes))
        }
    }

    //Controlador de selecor de dispositivos.
    const handleDeviceSelectedChange = (e) => {
        setStateNavIcon({})
        let value = e.target.value
        setValueDeviceSelected(value)
        getplcCompareDiagram(value?.id)
    }

    //Se encarga de cambiar el ICON de estado de el diagrama.
    const changeIconStateDiagram = async () => {
        let flag = 1
        const auxFunction = async () => {
            let res = await getStateSendDiagram(valueDeviceSelected?.id)
            let valueAux = {
                key: res?.data?.state,
                value: res?.data?.state_value
            }
            setStateNavIcon(valueAux)
            if (valueAux?.key === 'OK' || valueAux?.key === 'ERROR_COMUNICATION' || valueAux?.key === 'ERROR_BLOCKS' || valueAux?.key === 'UNSPECIFIED') {
                flag = 8
            }
        }
        setInterval(() => {
            flag += 1
            if (flag < 9) {
                auxFunction()
            }
        }, 4000)
    }

    //Envia al dispositivo plc el diagrama
    const handleSubmitDeviceConnect = async () => {
        setLoadingSave(true)
        try {
            let body = {
                automates_id: [`${valueDeviceSelected?.id}`]
            }
            //Carga diagrama en plc
            putDiagramInAutomate(params_id, body)
            changeIconStateDiagram(valueDeviceSelected?.id)
            setStateNavIcon({
                key: "PROCESS_LOAD",
                value: "Carga en Proceso"
            })
            setMsg({
                msg: "Se ha enviado su configuración al PLC. En un momento recibirá la confirmación sobre si la operación fue exitosa.",
                type: "success"
            })
        }
        catch (error) {
            let msg = ''
            if (error.response) {
                msg = `${error.response.data.msg}.`
            }
            else {
                msg = `Ocurrió un error inesperado`
            }
            setMsg({
                msg: msg,
                type: "error"
            })
        }
        setTimeout(() => {
            setLoadingSave(false)
        }, 1000)
        setClose(true)
    }

    return (
        <>
            {/* Panel de eliminacion dashboard. */}
            <DeletePanel
                openDeletePanel={openDeletePanel}
                handleOpenCloseDeletePanel={handleOpenCloseDeletePanel}
                deleteLocal={cancel}
            />

            {/* Container loading */}
            <LoadingComponentBlur
                loadingState={loadingSave}
            />

            {/* Modal name de diagrama personalizado */}
            < ModalName
                diagramName={diagramName?.name}
                editName={editName}
                setDiagramName={setDiagramName}
                setEditName={setEditName}
            />

            <ConfirmationDialog
                isOpen={isDeleteDialogOpen}
                handleConfirm={deleteFunction}
                handleCancel={() => setIsDeleteDialogOpen(false)}
                title={`¿Desea eliminar su ${componentType === 'ALERTS_VARIABLES' ? 'alerta' : 'diagrama'}? Este proceso es irreversible`}
                content={`En caso de confirmar ${componentType === 'ALERTS_VARIABLES' ? 'la alerta sera eliminada' : 'el diagrama sera eliminado'}`}
            />

            <TopNav
                titulo={componentType === 'Automate-24V-4M' ? "Diagrama dinámico" : stateDiagram?.alertObject?.alert?.name ? stateDiagram?.alertObject?.alert?.name : ''}
                subtitulo={` ${''} `}
                changeTitleStyleFlex={true}
                marginLeft={true}
                iconButton={returnIconButtonNav()}
            >

                <Grid container justifyContent='flex-end' alignItems='center' display='flex'>
                    {componentType === 'CREATOR' &&
                        <Box ml={5} display='flex' alignItems='center'>
                            <Box mr={3} >
                                <Tooltip
                                    title={`Estado ${stateNavIcon?.value}`}
                                    arrow
                                >
                                    <Box mt={0.6} ml={1} style={{ cursor: 'pointer' }}>
                                        {returnIconState(stateNavIcon)}
                                    </Box>
                                </Tooltip>
                            </Box>
                        </Box>
                    }

                    <Box display='flex' alignItems={'center'}>
                        {componentType !== 'Automate-24V-4M' &&
                            <>
                                {/* Selector de dispositivos plc (Elemento hardware) */}
                                {componentType === 'CREATOR' &&
                                    <>
                                        <Box mr={3}>
                                            <FormControl
                                                className={classes.formControl} // Aplica la clase al FormControl
                                                variant="standard"
                                                disabled={dataDiagramList.length === 0}
                                            >
                                                <InputLabel
                                                    className={classes.label} // Aplica la clase al InputLabel
                                                    shrink={false}

                                                >
                                                    {dataDiagramList.length > 0 ? (valueDeviceSelected ? '' : 'Seleccione dispositivo') : 'No posee dispositivos.'}
                                                </InputLabel>

                                                <Select
                                                    className={classes.select} // Aplica la clase al Select
                                                    classes={{
                                                        icon: classes.selectIcon, // Clase para la flechita del selector
                                                    }}
                                                    value={valueDeviceSelected ? valueDeviceSelected : ''}
                                                    onChange={handleDeviceSelectedChange}
                                                // disabled={filter.variable === ''}
                                                >
                                                    {dataDiagramList.map(option => (
                                                        <MenuItem
                                                            key={option.id} value={option}>
                                                            {option.llave}
                                                        </MenuItem>
                                                    ))
                                                    }
                                                </Select>
                                            </FormControl>
                                        </Box>

                                        {/* Boton para cargar diagrama */}
                                        <Box>
                                            <Tooltip
                                                title={'Cargar diagrama en plc.'}
                                                arrow
                                                placement="right"
                                            >
                                                <Button
                                                    disabled={valueDeviceSelected ? false : true}
                                                    onClick={handleSubmitDeviceConnect}
                                                    variant="contained"
                                                    size="small">
                                                    <SendAndArchiveIcon />
                                                </Button>
                                            </Tooltip>
                                        </Box>
                                        {/* Boton Realtime */}
                                        <Box ml={1}>
                                            <Tooltip
                                                title={'Activar realtime.'}
                                                arrow
                                                placement="right"
                                            >
                                                <Button
                                                    disabled={realTimeButtonDisabled}
                                                    variant="contained"
                                                    size="small"
                                                >
                                                    {/* Contenido del botón */}
                                                    {stateRealTimeButton ?
                                                        <TrackChangesIcon
                                                            className={classes.animatedIcon}
                                                        />
                                                        :
                                                        <CloudOffIcon />
                                                    }
                                                </Button>
                                            </Tooltip>
                                        </Box>
                                    </>
                                }

                                <Tooltip
                                    title={'Guardar diagrama.'}
                                    arrow
                                    placement="right"
                                >
                                    <span>
                                        <Button
                                            style={{ marginLeft: "10px" }}
                                            className={disabledButtonSave() ? classes.btnDisabled : classes.btn}
                                            type="button"
                                            onClick={save}
                                            disabled={disabledButtonSave()}
                                            size="small"
                                        >
                                            {`Guardar`}
                                        </Button>
                                    </span>
                                </Tooltip>
                            </>

                        }
                        {componentType !== 'ALERTS_VARIABLES' && !isMobile &&
                            <>
                                {/* {ButtonImportExport()} */}
                                {componentType !== 'Automate-24V-4M' &&
                                    <Tooltip
                                        title={'Importar diagrama'}
                                        arrow
                                        placement="right"
                                    >
                                        <span>
                                            <input
                                                type="file"
                                                // accept=".json  .txt"
                                                onChange={handleFileChange}
                                                ref={fileInputRef}
                                                style={{ display: 'none' }} // Oculta el input de archivo
                                            />
                                            <Button
                                                style={{ marginLeft: '10px' }}
                                                className={classes.btn}
                                                type="button"
                                                onClick={handleUploadFile}
                                                size="small"
                                            >
                                                Importar
                                            </Button>
                                        </span>
                                    </Tooltip>
                                }
                                <Tooltip
                                    title={'Exportar diagrama'}
                                    arrow
                                    placement="right"
                                >
                                    <span>
                                        <Button
                                            style={{ marginLeft: "10px" }}
                                            className={classes.btn}
                                            type="button"
                                            onClick={handleOpenSelectFileDownload}
                                            size="small"
                                        >
                                            Exportar
                                            {openSelectFile &&
                                                <Box
                                                    ref={containerRef} // Asigna la referencia al contenedor
                                                    style={{
                                                        zIndex: 2,
                                                        position: 'absolute',
                                                        borderRadius: '4px',
                                                        top: '10px',
                                                        width: '90px',
                                                        backgroundColor: '#eae0e0', // Color de fondo
                                                        boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.3)' // Sombra
                                                    }}
                                                >
                                                    <Button
                                                        className={classes.btnFileDownload}
                                                        onClick={() => handleDownloadFile('json')}
                                                        type="button"
                                                        size="small"
                                                    >
                                                        Powermate
                                                    </Button>
                                                    <Button
                                                        className={classes.btnFileDownload}
                                                        onClick={() => handleDownloadFile('plc')}
                                                        type="button"
                                                        size="small"
                                                    >
                                                        Automate
                                                    </Button>
                                                </Box>
                                            }
                                        </Button>
                                    </span>
                                </Tooltip>
                            </>
                        }
                    </Box>
                </Grid>
            </TopNav >

            {/* ALERTAS */}
            < AlertComponent
                severity={msg.type}
                msg={msg.msg}
                setClose={setClose}
                close={close}
            />

            <Container >
                {loading ?
                    <Box display="flex" justifyContent="center" alignItems={'center'} height={'200px'}>
                        <CircularProgress />
                    </Box>
                    :
                    <Box style={{ width: '100%', height: `calc(100vh - 118px)` }} >
                        <Root
                            resetZoom={resetZoom}
                            isMobile={isMobile}
                            componentType={componentType}
                            edges={edges}
                            nodes={nodes}
                            onNodesChange={onNodesChange}
                            onEdgesChange={onEdgesChange}
                            onConnect={onConnect}
                            onConnectEnd={onConnectEnd}
                            onDrop={onDrop}
                            onSelectionDrag={onSelectionDrag}
                            onConnectStart={onConnectStart}
                            setReactFlowInstance={setReactFlowInstance}
                        />
                        {/* <WebSocketComponent /> */}
                    </Box>
                }
            </Container>
        </>
    )
}
export default DynamicDiagram



