import React, { useContext, useEffect, useState } from 'react';
import { NavLink, useHistory } from 'react-router-dom';
import clsx from 'clsx';
import {
  Box,
  Collapse,
  Drawer as MUIDrawer,
  Menu,
  MenuItem,
  AppBar,
  ClickAwayListener,
  IconButton,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Toolbar,
  Tooltip
} from '@material-ui/core';
import { makeStyles, useTheme } from '@material-ui/core/styles';
// iconos
import AccountCircleOutlinedIcon from '@material-ui/icons/AccountCircleOutlined';
import AuditorIcon from '@material-ui/icons/VerifiedUser';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import ControlIcon from '@material-ui/icons/ControlCamera';
import DashboardIcon from '@material-ui/icons/Dashboard';
import DescriptionIcon from '@material-ui/icons/Description';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import HelpOutlineOutlinedIcon from '@material-ui/icons/HelpOutlineOutlined';
import HomeIcon from '@mui/icons-material/Home';
import LocalAtmIcon from '@material-ui/icons/LocalAtm';
import MenuIcon from '@material-ui/icons/Menu';
import SettingsOutlinedIcon from '@material-ui/icons/SettingsOutlined';
import TimelineIcon from '@material-ui/icons/Timeline';
import UserContext from '../../context/UserContext';
import { getTokenFromLocalStorage } from '../../services/auth';
import { getDashboards } from '../../services/dashboard';
import ReportGmailerrorredIcon from '@mui/icons-material/ReportGmailerrorred';
// Custom Hooks
import useDeviceDetect from "../../hooks/useDeviceDetect";
import { subscription } from '../../service_worker/helpers';
import NotificationIconComponent from '../NotificationIconComponent/NotificationIconComponent';

const drawerWidth = 270;
let scrollsSettings = {
  overflow: "scroll",
  maxHeight: "150px",
  overflowX: "hidden",
  background: "#58a7d3",
  marginRight: "3px"
}
let webkitScrollbar = {
  background: "white",
  borderStyle: "solid",
  borderWidth: "1px",
  borderColor: "#beb1b18f",
  color: "red",
  width: "6px"
}
let webkitscrollbarTrack = {
  background: "#58a7d3",
}

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    //estilo agregado para q en la funcion windows.print() este componente nunca este en la imagen de el pdf.
    "@media print": {
      display: 'none'
    }
  },
  modalOverlay: {
    zIndex: 100,
    position: 'fixed',
    width: '100vw',
    height: '100vh',
    top: 0,
    left: 0,
    backgroundColor: ' rgba(0, 0, 0, 0.6)',
  },
  appBar: {
    zIndex: theme.zIndex.drawer - 1,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  appBarShift: {
    zIndex: theme.zIndex.drawer - 1,
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  menuButton: {
    marginRight: 36,
  },
  hide: {
    display: 'none',
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: 'nowrap',
  },
  drawerOpen: {
    width: drawerWidth,
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
    background: theme.palette.primary.light,
    color: theme.palette.common.white,

    '& .MuiListItemIcon-root': {
      color: theme.palette.common.white,
    },

    '& .MuiListItem-root': {
      color: theme.palette.common.white,
    },
    '&::-webkit-scrollbar': {
      width: '0.8em',
    },
    '&::-webkit-scrollbar-track': {
      boxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)',
      webkitBoxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)',
    },
    '&::-webkit-scrollbar-thumb': {
      background: theme.palette.primary.light,
    },
  },
  drawerClose: {
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: 'hidden',
    width: theme.spacing(8) + 1,
    [theme.breakpoints.up('sm')]: {
      width: theme.spacing(8) + 1,
    },
    '& .MuiListItemIcon-root': {
      color: theme.palette.primary.main,
    },
  },
  toolbar1: {
    '& .MuiIconButton-root': {
      padding: theme.spacing(1),
    },
  },
  toolbarOpen: {
    display: 'flex',
    alignItems: 'center',
    marginLeft: theme.spacing(0.5),
    '& .MuiIconButton-root': {
      color: theme.palette.common.white,
    },
  },
  toolbarClose: {
    display: 'flex',
    alignItems: 'center',
    marginLeft: theme.spacing(2),
    '& .MuiIconButton-label': {
      color: theme.palette.primary,
    },
  },
  nested: {
    paddingLeft: theme.spacing(8),
    color: theme.palette.primary.main,
  },
  //Agregado para quitar el borde azul al drawer
  paperAnchorDockedLeft: {
    border: 'none',
  },
  username: {
    color: theme.palette.primary.main,
    textAlign: 'center',
  },
  plan: {
    textAlign: 'center',
    textDecoration: 'underline',
  },
  userMenu: {
    '& .MuiPaper-root': {
      top: `${theme.spacing(8)}px !important`,
    },
  },
  HamburgerMenuIcon: {
    zIndex: '1',
    position: 'absolute',
    left: '15px',
  },
  mobileDisable: {
    '@media (max-width: 920px)': {
      display: 'none',
    },
  },
  mobileDisableDashboard: {
    '@media (max-width: 1080px)': {
      display: 'none',
    },
  },
  navLink: {
    textDecoration: 'none',
    color: 'inherit',
  },
  bold: {
    fontWeight: 'bold',
  },
  wordCut: {
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
  },
  dashboardList: {
    //son los estilos de las barras scroll en una variable.
    ...scrollsSettings,
    '&::-webkit-scrollbar': {
      //son los estilos de las barras scroll en una variable.
      ...webkitScrollbar
    },
    '&::-webkit-scrollbar-track': {
      //son los estilos de las barras scroll en una variable.
      ...webkitscrollbarTrack
    },
    '&::-webkit-scrollbar-thumb': {
      background: theme.palette.primary.main,
    },
  },
  logoImg: {
    '@media (max-width: 350px)': {
      display: 'none',
    },
  }
}));

const Drawer = ({ handleLogout, refresh }) => {
  const classes = useStyles();
  const theme = useTheme();
  const { plan } = useContext(UserContext);
  const [open, setOpen] = useState(false);
  const [openMenuAnalisis, setOpenMenuAnalisis] = useState(false);
  const [openMenuDashboard, setOpenMenuDashboard] = useState(false);
  const [openMenuFacturacion, setOpenMenuFacturacion] = useState(false);
  const [show, setShow] = useState(false);
  const [dataDashboardDinamic, setDataDashboardDinamic] = useState([]);
  //Estado encargado de resetear la campanita de notificaciones.
  const [clean, setClean] = useState(true);
  let history = useHistory();
  const { isMobile } = useDeviceDetect(920);
  const [openMenuAlertsAndReports, setOpenMenuAlertsAndReports] = useState(false);
  const [openMenuControl, setOpenMenuAlertsControl] = useState(false);
  const [openMenuDiagramList, setOpenMenuDiagramList] = useState(false);

  useEffect(() => {
    onResize();
  }, [])

  //Carga los dashboard del usuario.
  useEffect(() => {
    const getDataDashboard = async () => {
      let res = await getDashboards()
      setDataDashboardDinamic(res.data);
    }
    if (plan) {
      getDataDashboard()
    }
    // eslint-disable-next-line
  }, [refresh]);

  //Identifico vista mobile o desktop para q la tabla muestre solo total.
  useEffect(() => {
    window.addEventListener('resize', onResize, { passive: true });
  });

  //Estado service worker
  useEffect(() => {
    if (plan?.info?.email) {
      subscription(plan, getTokenFromLocalStorage())
    }
    // eslint-disable-next-line
  }, [plan]);

  //Funcion para identificar vista mobile.
  const onResize = () => {
    if (document.body.clientWidth <= 920) {
      setShow(false);
    }
    else {
      setShow(true);
    }
  };

  const handleDrawerOpen = () => {
    setOpen(true);
    //mobile
    setShow(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
    if (isMobile) {
      //mobile
      setShow(false);
    }
    //Comprimo los menus de sub-opciones
    setOpenMenuDashboard(false);
    setOpenMenuAnalisis(false);
    setOpenMenuFacturacion(false);
    setOpenMenuAlertsAndReports(false);
    setOpenMenuAlertsControl(false)
    setOpenMenuDiagramList(false)
  };

  const handleOpenMenuDashboard = () => {
    //Abro el drawer
    setOpen(true);
    //Comprimo/expando sub-opciones
    setOpenMenuDashboard(!openMenuDashboard);
  };

  const handleOpenMenuAnalisis = () => {
    //Abro el drawer
    setOpen(true);
    //Comprimo/expando sub-opciones
    setOpenMenuAnalisis(!openMenuAnalisis);
  };

  const handleOpenMenuFacturacion = () => {
    //Abro el drawer
    setOpen(true);
    //Comprimo/expando sub-opciones
    setOpenMenuFacturacion(!openMenuFacturacion);
  };

  const handleOpenMenuAlertsAndReports = () => {
    //Abro el drawer
    setOpen(true);
    //Comprimo/expando sub-opciones
    setOpenMenuAlertsAndReports(!openMenuAlertsAndReports);
  };

  const handleOpenMenuControl = () => {
    //Abro el drawer
    setOpen(true);
    //Comprimo/expando sub-opciones
    setOpenMenuAlertsControl(!openMenuControl);
  };

  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = event => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setClean(false)
    setAnchorEl(null);
  };

  const disabledTextLink = variation => {
    if (variation === false) {
      return { pointerEvents: 'none', cursor: 'not-allowed' };
    } else {
      return {};
    }
  };

  return (
    <>
      {plan && (
        <div
          className={clsx(classes.root, {
            [classes.modalOverlay]: open,
          })}
        >
          <AppBar
            style={{ backgroundColor: '#FFF' }}
            position="fixed"
            className={clsx(classes.appBar, {
              [classes.appBarShift]: open,
            })}
          >
            {/* Icon Hamburger Menu */}
            {!show && isMobile && (
              <div className={classes.HamburgerMenuIcon}>
                <IconButton
                  color="primary"
                  aria-label="open drawer"
                  onClick={handleDrawerOpen}
                  edge="start"
                  className={classes.menuButton}
                >
                  <MenuIcon />
                </IconButton>
              </div>
            )}

            <Toolbar variant="dense" className={classes.toolbar1}>
              <div style={{ position: 'fixed', right: 0 }}>

                {/* NOTFICACIONES */}
                {
                  plan?.info &&
                  < NotificationIconComponent clean={clean} />
                }

                {/* CONFIGURACION */}
                <Tooltip title="Configuración">
                  <NavLink className={classes.navLink} to={`/configuracion`}>
                    <IconButton color="primary" aria-label="Configuración">
                      <SettingsOutlinedIcon />
                    </IconButton>
                  </NavLink>
                </Tooltip>

                {/* SOPORTE */}
                <Tooltip title="Soporte">
                  <IconButton
                    color="primary"
                    aria-label="Soporte"
                    href="mailto:soporte@powermeter.com.ar"
                  >
                    <HelpOutlineOutlinedIcon />
                  </IconButton>
                </Tooltip>

                {/* lOGIN*/}
                {!getTokenFromLocalStorage() ? (
                  <Tooltip title="Iniciar Sesión">
                    <IconButton
                      className={classes.topIcon}
                      color="primary"
                      aria-label="Iniciar Sesión"
                      onClick={() => history.push('/login')}
                    >
                      <AccountCircleOutlinedIcon />
                    </IconButton>
                  </Tooltip>
                ) : (
                  <>
                    <Tooltip title={plan?.info?.username || 'User'}>
                      <IconButton
                        color="primary"
                        aria-controls="simple-menu"
                        aria-haspopup="true"
                        onClick={handleClick}
                      >
                        <AccountCircleOutlinedIcon />
                      </IconButton>
                    </Tooltip>
                    <Menu
                      className={classes.userMenu}
                      id="simple-menu"
                      anchorEl={anchorEl}
                      keepMounted
                      open={Boolean(anchorEl)}
                      onClose={handleClose}
                    >
                      <div className={classes.username}>
                        @{plan?.info?.username}
                      </div>
                      <div className={classes.plan}>
                        Plan {plan?.info?.plan}
                      </div>
                      <NavLink
                        className={classes.navLink}
                        to={`.${`/configuracion-cuenta`}`}
                      >
                        <MenuItem
                          onClick={() => {
                            handleClose();
                          }}
                        >
                          Configuración Cuenta
                        </MenuItem>
                      </NavLink>
                      <MenuItem
                        onClick={() => {
                          handleLogout();
                          handleClose();
                        }}
                      >
                        Cerrar Sesión
                      </MenuItem>
                    </Menu>
                  </>
                )}
              </div>

              <img
                className={classes.logoImg}
                src="/images/logo.svg"
                alt="logo"
                style={{ position: 'fixed', left: 75, height: '1.25em' }}
              />
            </Toolbar>
          </AppBar>

          {show && (
            <div>
              <ClickAwayListener onClickAway={handleDrawerClose}>
                <MUIDrawer
                  variant="permanent"
                  className={clsx(classes.drawer, {
                    [classes.drawerOpen]: open,
                    [classes.drawerClose]: !open,
                  })}
                  classes={{
                    paper: clsx({
                      [classes.drawerOpen]: open,
                      [classes.drawerClose]: !open,
                    }),
                    paperAnchorDockedLeft: classes.paperAnchorDockedLeft,
                  }}
                >
                  {open ? (
                    <div className={classes.toolbarOpen}>
                      <IconButton onClick={handleDrawerClose}>
                        {theme.direction === 'rtl' ? (
                          <ChevronRightIcon />
                        ) : (
                          <ChevronLeftIcon />
                        )}
                      </IconButton>
                    </div>
                  ) : (
                    <div className={classes.toolbarClose}>
                      <IconButton
                        color="primary"
                        aria-label="open drawer"
                        onClick={handleDrawerOpen}
                        edge="start"
                        className={classes.menuButton}
                      >
                        <MenuIcon />
                      </IconButton>
                    </div>
                  )}

                  {/* Inicio*/}
                  < NavLink
                    className={classes.navLink}
                    to={`/`}
                  >
                    <ListItem
                      button
                      onClick={() => {
                        handleDrawerClose();
                      }}
                    >
                      <Tooltip title="Inicio" arrow>
                        <ListItemIcon>
                          <HomeIcon />
                        </ListItemIcon>
                      </Tooltip>
                      <ListItemText primary="Inicio" />
                    </ListItem>
                  </NavLink>

                  <List disablePadding={true}>
                    {/*Dashboards*/}
                    <ListItem
                      button
                      onClick={handleOpenMenuDashboard}
                      disabled={!plan?.access}
                    >
                      <Tooltip title="Dashboards" arrow>
                        <ListItemIcon>
                          <DashboardIcon />
                        </ListItemIcon>
                      </Tooltip>
                      <ListItemText primary="Dashboards" />
                      {openMenuDashboard ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                    </ListItem>

                    <Collapse
                      in={openMenuDashboard}
                      timeout="auto"
                      unmountOnExit
                    >
                      <List component="div" disablePadding>

                        {/*Personalizado*/}
                        <NavLink
                          className={clsx(classes.navLink)}
                          to={`/crear-personalizado`}
                          style={disabledTextLink(
                            plan?.access?.dashboards_creacion
                          )}
                        >
                          <ListItem
                            disabled={!plan?.access?.dashboards_creacion}
                            dense
                            button
                            className={clsx(
                              classes.mobileDisableDashboard,
                              classes.nested,
                              classes.bold
                            )}
                            onClick={() => {
                              handleDrawerClose();
                            }}
                          >
                            <ListItemText
                              primary={<strong>{'Nuevo dashboard'}</strong>}
                            />
                          </ListItem>
                        </NavLink>

                        {/*Resumen dispositivo*/}
                        <NavLink
                          className={classes.navLink}
                          to={`/resumen`}
                          style={disabledTextLink(
                            plan?.access?.dashboards_resumen
                          )}
                        >
                          <ListItem
                            disabled={!plan?.access?.dashboards_resumen}
                            dense
                            button
                            className={classes.nested}
                            onClick={() => {
                              handleDrawerClose();
                            }}
                          >
                            <ListItemText primary="Resumen dispositivo" />
                          </ListItem>
                        </NavLink>

                        {/*Estado*/}
                        <NavLink
                          className={classes.navLink}
                          to={`/estado`}
                          style={disabledTextLink(
                            plan?.access?.dashboards_estado
                          )}
                        >
                          <ListItem
                            disabled={!plan?.access?.dashboards_estado}
                            dense
                            button
                            className={classes.nested}
                            onClick={() => {
                              handleDrawerClose();
                            }}
                          >
                            <ListItemText primary="Estado" />
                          </ListItem>
                        </NavLink>

                        {/*Resumen general*/}
                        <NavLink
                          className={classes.navLink}
                          to={`/resumen_general`}
                          style={disabledTextLink(
                            plan?.access?.dashboard_empresa
                          )}
                        >
                          <ListItem
                            disabled={!plan?.access?.dashboard_empresa}
                            dense
                            button
                            className={classes.nested}
                            onClick={() => {
                              handleDrawerClose();
                            }}
                          >
                            <ListItemText primary="Resumen general" />
                          </ListItem>
                        </NavLink>

                        {/*Resumen localización*/}
                        <NavLink
                          className={classes.navLink}
                          to={`/resumen_localizacion`}
                          style={disabledTextLink(
                            plan?.access?.dashboard_localizacion
                          )}
                        >
                          <ListItem
                            disabled={!plan?.access?.dashboard_localizacion}
                            dense
                            button
                            className={clsx(classes.nested)}
                            onClick={() => {
                              handleDrawerClose();
                            }}
                          >
                            <ListItemText primary="Resumen localización" />
                          </ListItem>
                        </NavLink>

                        {/*Mapa*/}
                        <NavLink
                          className={classes.navLink}
                          to={`/mapa`}
                          style={disabledTextLink(
                            plan?.access?.dashboards_mapa
                          )}
                        >
                          <ListItem
                            disabled={!plan?.access?.dashboards_mapa}
                            dense
                            button
                            className={clsx(
                              classes.mobileDisable,
                              classes.nested
                            )}
                            onClick={() => {
                              handleDrawerClose();
                            }}
                          >
                            <ListItemText primary="Mapa" />
                          </ListItem>
                        </NavLink>

                        {/*Ecoloico*/}
                        {/* {plan?.info?.plan === 'Staff' && */}
                        <NavLink
                          className={classes.navLink}
                          to={`${`/ecologico`}`}
                          style={disabledTextLink(plan?.access?.dashboard_ecologico)}
                        >
                          <ListItem
                            disabled={!plan?.access?.dashboard_ecologico}
                            dense
                            button
                            className={clsx(
                              classes.mobileDisable,
                              classes.nested
                            )}
                            onClick={() => {
                              handleDrawerClose();
                            }}
                          >
                            <ListItemText primary="Ecológico" />
                          </ListItem>
                        </NavLink>
                        {/* } */}

                        {/* Solar */}
                        <NavLink
                          className={classes.navLink}
                          to={`${`/solar`}`}
                          style={disabledTextLink(plan?.access?.dashboard_solar)}
                        >
                          <ListItem
                            disabled={!plan?.access?.dashboard_solar}
                            dense
                            button
                            className={clsx(
                              classes.mobileDisable,
                              classes.nested
                            )}
                            onClick={() => {
                              handleDrawerClose();
                            }}
                          >
                            <ListItemText primary="Solar" />
                          </ListItem>
                        </NavLink>

                        {plan?.access?.dashboards_creacion &&
                          <>
                            {/* Linea q separa blanca los dashboard personalizados. */}
                            <Box
                              style={{
                                borderBottom: 'solid 1px white',
                                marginLeft: '64px',
                                width: '64%',
                              }}
                              className={classes.navLink}
                            >
                            </Box>

                            {/*Dashboards creados titulo*/}
                            <Box >
                              <ListItem
                                dense
                                className={clsx(
                                  classes.nested,
                                  classes.bold
                                )}
                              >
                                <ListItemText
                                  primary={<strong>{'Dashboards creados'}</strong>}
                                />
                                {
                                  dataDashboardDinamic.length === 0 &&
                                  <Box style={{ cursor: 'pointer' }} ml={8} mt={1} fontWeight={'500'} >
                                    <Tooltip title={'No posee dashboards'}>
                                      <ReportGmailerrorredIcon />
                                    </Tooltip>
                                  </Box>
                                }
                              </ListItem>
                            </Box>

                            {/* Linea q separa blanca los Diagramas personalizados. */}
                            <Box
                              style={{
                                borderBottom: 'solid 1px white',
                                marginLeft: '64px',
                                width: '64%',
                              }}
                              className={classes.navLink}
                            >
                            </Box>

                            {/*Dashboards creados lista*/}
                            <Box className={classes.dashboardList}>
                              {dataDashboardDinamic?.map((element, index) => {
                                return (
                                  <Tooltip key={index} title={element?.name}>
                                    <NavLink
                                      className={classes.navLink}
                                      to={`/personalizado/${element?.id}`}
                                      style={disabledTextLink(
                                        plan?.access?.dashboards_creacion
                                      )}
                                    >
                                      <ListItem
                                        disabled={!plan?.access?.dashboards_creacion}
                                        dense
                                        button
                                        className={clsx(
                                          classes.nested
                                        )}
                                        onClick={() => {
                                          handleDrawerClose();
                                        }}
                                      >
                                        <ListItemText
                                          primary={
                                            <div className={classes.wordCut}>
                                              {element?.name}
                                            </div>
                                          }
                                        />
                                      </ListItem>
                                    </NavLink>
                                  </Tooltip>
                                );
                              }
                              )}
                            </Box>
                          </>
                        }
                      </List>
                    </Collapse>

                    {/*Auditor*/}
                    <NavLink
                      className={classes.navLink}
                      to={`.${`/auditor`}`}
                      style={disabledTextLink(plan?.access?.auditor)}
                    // style={disabledTextLink(false)}
                    >
                      <ListItem
                        disabled={!plan?.access?.auditor}
                        // disabled={true}
                        button
                        onClick={() => {
                          handleDrawerClose();
                        }}
                      >
                        <Tooltip title="Auditor" arrow>
                          <ListItemIcon>
                            <AuditorIcon />
                          </ListItemIcon>
                        </Tooltip>
                        <ListItemText primary="Auditor" />
                      </ListItem>
                    </NavLink>

                    {/*Facturación*/}
                    <ListItem
                      disabled={!plan?.access}
                      button
                      onClick={handleOpenMenuFacturacion}
                    >
                      <Tooltip title="Facturación" arrow>
                        <ListItemIcon>
                          <LocalAtmIcon />
                        </ListItemIcon>
                      </Tooltip>
                      <ListItemText primary="Facturación" />
                      {openMenuFacturacion ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                    </ListItem>

                    {/*Factura*/}
                    <Collapse
                      in={openMenuFacturacion}
                      timeout="auto"
                      unmountOnExit
                    >
                      <List component="div" disablePadding>

                        {/*Calculador simple*/}
                        {plan?.access?.facturacion_factura_free && (
                          <NavLink
                            className={classes.navLink}
                            to={`/calculador`}
                            style={disabledTextLink(
                              plan?.access?.facturacion_factura_free
                            )}
                          >
                            <ListItem
                              disabled={false}
                              dense
                              button
                              className={classes.nested}
                              onClick={() => {
                                handleDrawerClose();
                              }}
                            >
                              <ListItemText primary="Calculador simple" />
                            </ListItem>
                          </NavLink>
                        )}

                        <NavLink
                          className={classes.navLink}
                          to={`/factura`}
                          style={disabledTextLink(
                            plan?.access?.facturacion_factura
                          )}
                        >
                          <ListItem
                            disabled={!plan?.access?.facturacion_factura}
                            dense
                            button
                            className={clsx(
                              classes.mobileDisable,
                              classes.nested
                            )}
                            onClick={() => {
                              handleDrawerClose();
                            }}
                          >
                            <ListItemText primary="Factura" />
                          </ListItem>
                        </NavLink>


                        {/*Conciliación*/}
                        <NavLink
                          className={classes.navLink}
                          to={`/conciliacion`}
                          style={disabledTextLink(
                            plan?.access?.facturacion_conciliacion
                          )}
                        >
                          <ListItem
                            disabled={!plan?.access?.facturacion_conciliacion}
                            dense
                            button
                            className={clsx(
                              classes.mobileDisable,
                              classes.nested
                            )}
                            onClick={() => {
                              handleDrawerClose();
                            }}
                          >
                            <ListItemText primary="Conciliación" />
                          </ListItem>
                        </NavLink>

                        {/*Subdivisión*/}
                        <NavLink
                          className={classes.navLink}
                          to={`/sub_factura`}
                          style={disabledTextLink(
                            plan?.access?.facturacion_subdivision
                          )}
                        >
                          <ListItem
                            disabled={!plan?.access?.facturacion_subdivision}
                            dense
                            button
                            className={classes.nested}
                            onClick={() => {
                              handleDrawerClose();
                            }}
                          >
                            <ListItemText primary="Subfactura" />
                          </ListItem>
                        </NavLink>
                      </List>
                    </Collapse>

                    {/*Analisis*/}
                    <ListItem
                      button
                      onClick={handleOpenMenuAnalisis}
                      disabled={!plan?.access}
                    >
                      <Tooltip title="Analisis" arrow>
                        <ListItemIcon>
                          <TimelineIcon />
                        </ListItemIcon>
                      </Tooltip>
                      <ListItemText primary="Análisis" />
                      {openMenuAnalisis ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                    </ListItem>

                    {/*Instantáneos*/}
                    <Collapse
                      in={openMenuAnalisis}
                      timeout="auto"
                      unmountOnExit
                    >
                      <List component="div" disablePadding>
                        <NavLink
                          className={classes.navLink}
                          to={`/instantaneos`}
                          style={disabledTextLink(
                            plan?.access?.analisis_instantaneos
                          )}
                        >
                          <ListItem
                            disabled={!plan?.access?.analisis_instantaneos}
                            dense
                            button
                            className={classes.nested}
                            onClick={() => {
                              handleDrawerClose();
                            }}
                          >
                            <ListItemText primary="Instantáneos" />
                          </ListItem>
                        </NavLink>

                        {/*Históricos*/}
                        <NavLink
                          className={classes.navLink}
                          to={`/historicos`}
                          style={disabledTextLink(
                            plan?.access?.analisis_historicos
                          )}
                        >
                          <ListItem
                            disabled={!plan?.access?.analisis_historicos}
                            dense
                            button
                            className={classes.nested}
                            onClick={() => {
                              handleDrawerClose();
                            }}
                          >
                            <ListItemText primary="Históricos" />
                          </ListItem>
                        </NavLink>

                        {/*Calidad*/}
                        <NavLink
                          className={classes.navLink}
                          to={`/calidad`}
                          style={disabledTextLink(
                            plan?.access?.analisis_calidad
                          )}
                        >
                          <ListItem
                            disabled={!plan?.access?.analisis_calidad}
                            dense
                            button
                            className={clsx(
                              classes.mobileDisable,
                              classes.nested
                            )}
                            onClick={() => {
                              handleDrawerClose();
                            }}
                          >
                            <ListItemText primary="Calidad" />
                          </ListItem>
                        </NavLink>

                        {/* Heatmap*/}
                        < NavLink
                          className={classes.navLink}
                          to={`/heatmap`}
                          style={disabledTextLink(
                            plan?.access?.analisis_heatmap
                          )}
                        >
                          <ListItem
                            disabled={!plan?.access?.analisis_heatmap}
                            dense
                            button
                            className={classes.nested}
                            onClick={() => {
                              handleDrawerClose();
                            }}
                          >
                            <ListItemText primary="Heatmap" />
                          </ListItem>
                        </NavLink>
                      </List>
                    </Collapse>

                    {/* Alertas y Reportes */}
                    <ListItem
                      button
                      onClick={handleOpenMenuAlertsAndReports}
                      disabled={!plan?.access?.alertas_y_reportes}>
                      <Tooltip title="Alertas y reportes" arrow>
                        <ListItemIcon>
                          <DescriptionIcon />
                        </ListItemIcon>
                      </Tooltip>
                      <ListItemText primary="Alertas y reportes" />
                      {openMenuAlertsAndReports ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                    </ListItem>

                    {/*Informes*/}
                    <Collapse
                      in={openMenuAlertsAndReports}
                      timeout="auto"
                      unmountOnExit>
                      <List component="div" disablePadding>

                        {/*Informes*/}
                        <NavLink
                          className={classes.navLink}
                          to={`/informes`}
                          style={disabledTextLink(
                            plan?.access?.alertas_y_reportes_informes
                          )}
                        >
                          <ListItem
                            disabled={!plan?.access?.alertas_y_reportes_informes}
                            dense
                            button
                            className={classes.nested}
                            onClick={() => {
                              handleDrawerClose();
                            }}
                          >
                            <ListItemText primary="Informes" />
                          </ListItem>
                        </NavLink>

                        {/*Eventos*/}
                        <NavLink
                          className={classes.navLink}
                          to={`/eventos`}
                          style={disabledTextLink(
                            plan?.access?.alertas_y_reportes_eventos
                          )}
                        >
                          <ListItem
                            disabled={!plan?.access?.alertas_y_reportes_eventos}
                            dense
                            button
                            className={classes.nested}
                            onClick={() => {
                              handleDrawerClose();
                            }}
                          >
                            <ListItemText primary="Eventos" />
                          </ListItem>
                        </NavLink>

                        {/*Alarmas en dispositivos*/}
                        <NavLink
                          className={classes.navLink}
                          to={`/alarmas-en-dispositivos`}
                          style={disabledTextLink(
                            plan?.access?.alertas_y_reportes_alarmas
                          )}>
                          <ListItem
                            disabled={!plan?.access?.alertas_y_reportes_alarmas}
                            dense
                            button
                            className={classes.nested}
                            onClick={() => {
                              handleDrawerClose();
                            }}>
                            <ListItemText primary="Alarmas en dispositivos" />
                          </ListItem>
                        </NavLink>

                        {/*Diagrama Alertas y reportes*/}
                        {
                          <NavLink
                            className={classes.navLink}
                            to={`/alerts`}
                            style={disabledTextLink(plan?.access?.alertas_y_reportes_alertas)}
                          >
                            <ListItem
                              disabled={!plan?.access?.alertas_y_reportes_alertas}
                              dense
                              button
                              className={classes.nested}
                              onClick={() => {
                                handleDrawerClose();
                              }}
                            >
                              <ListItemText primary="Alertas" />
                            </ListItem>
                          </NavLink>
                        }
                      </List>
                    </Collapse>

                    {/* Control */}
                    <ListItem
                      button
                      onClick={handleOpenMenuControl}
                    // disabled={!plan?.access?.control}
                    >
                      <Tooltip title="Control" arrow>
                        <ListItemIcon>
                          <ControlIcon />
                        </ListItemIcon>
                      </Tooltip>
                      <ListItemText primary="Control" />
                      {openMenuControl ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                    </ListItem>

                    <Collapse
                      in={openMenuControl}
                      timeout="auto"
                      unmountOnExit>
                      <List component="div" disablePadding>
                        {/*Automate*/}
                        {
                          <NavLink
                            className={classes.navLink}
                            to={`/plc_list`}
                            style={disabledTextLink(plan?.access?.control_automate)}
                          >
                            <ListItem
                              disabled={!plan?.access?.control_automate}
                              dense
                              button
                              className={classes.nested}
                              onClick={() => {
                                handleDrawerClose();
                              }}
                            >
                              <ListItemText primary="Automate" />
                            </ListItem>
                          </NavLink>
                        }
                      </List>
                      <List component="div" disablePadding>
                        {/*Diagramas*/}
                        {
                          <NavLink
                            className={classes.navLink}
                            to={`/diagram_list`}
                            style={disabledTextLink(true)}
                          >
                            <ListItem
                              disabled={!true}
                              dense
                              button
                              className={classes.nested}
                              onClick={() => {
                                handleDrawerClose();
                              }}
                            >
                              <ListItemText primary="Diagramas" />
                            </ListItem>
                          </NavLink>
                        }
                      </List>

                    </Collapse>
                    <Collapse
                      in={openMenuDiagramList}
                      timeout="auto"
                      unmountOnExit>
                    </Collapse>
                  </List>
                </MUIDrawer>
              </ClickAwayListener>
            </div >
          )}
        </div >
      )}
    </>
  );
};

export default Drawer;


