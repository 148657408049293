import React, { useEffect, useState } from "react";
import {
    Box, Button, makeStyles, Dialog, TextField
} from '@material-ui/core';

const useStyles = makeStyles(theme => ({
    containerMain: {
        padding: theme.spacing(1),
    },
    btnSpacing: {
        margin: theme.spacing(0, 1),
    },
    dialogSubtitle: {
        color: theme.palette.primary.main,
        marginLeft: theme.spacing(1),
        fontSize: theme.spacing(2),
        '@media (max-width: 700px)': {
            display: 'none',
        },
    },
    inputName: {
        margin: theme.spacing(1, 0, 1, 0),
    },
    errorLabel: {
        color: "red",
        fontSize: theme.spacing(2),
    }
}));

export default function ModalName({ setDiagramName, setEditName, editName, diagramName }) {
    const classes = useStyles();
    const [inputState, setInputState] = useState("");
    const [errorMsg, setErrorMsg] = useState("");

    useEffect(() => {
        if (diagramName.length > 0) {
            setInputState(diagramName)
        }
    }, [diagramName])

    const validateForm = (string) => {
        if (string.length < 5) {
            setErrorMsg("El nombre debe poseer más de 4 caracteres")
            return
        }
        return true
    }

    const handleChange = (e) => {
        let name = e.target.value
        setInputState(name)
    }

    const save = () => {
        let spaceClean = inputState.trim()
        let nameAux = spaceClean
        if (validateForm(nameAux)) {
            setDiagramName({
                name: inputState
            })
            setEditName(false)
        }
    }

    return (
        <Dialog
            open={editName}
            onClose={() => setEditName(false)}
            aria-labelledby="modal_name"
            fullWidth
            disableScrollLock
        >
            <Box className={classes.containerMain}>
                <div className={classes.dialogSubtitle}>
                    Seleccionar un nombre para su diagrama.
                </div>

                <TextField
                    className={classes.inputName}
                    type="text"
                    fullWidth
                    label='Elija un nombre'
                    name='dashboard_name'
                    size='small'
                    variant='outlined'
                    color='primary'
                    value={inputState}
                    onChange={e => handleChange(e)}
                    InputLabelProps={{
                        shrink: true,
                    }}
                />

                <div className={classes.errorLabel}>
                    {errorMsg}
                </div>

                <Box display='flex' justifyContent='center'>
                    <Button
                        onClick={save}
                        variant='contained' color='primary' className={classes.btnSpacing}>
                        Guardar
                    </Button>
                    <Button
                        onClick={() => setEditName(false)}
                        variant='contained' className={classes.btnSpacing}>
                        Cancelar
                    </Button>
                </Box>
            </Box>
        </Dialog>
    )
}