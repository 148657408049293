import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import {
    Box,
    CircularProgress,
} from '@material-ui/core';
import { Alert } from '@mui/material';
//Component
import AlertPlcTable from '../common/AlertPlcTable';
import Container from '../Elements/Container';
import TopNav from '../common/TopNav';
import AlertComponent from '../Elements/AlertComponent';
//Helpers
import { putPlc_automate_24v_state_action, getPlc_automate_24v_list } from '../../services/plc_automate';

const PLC_AUTOMATE_24V = () => {
    let history = useHistory();
    const [plcList, setPlcListt] = useState([]);
    const [loading, setLoading] = useState(true);
    //Estado de la alerta dinamica
    const [close, setClose] = useState(false)
    const [msg, setMsg] = useState({
        msg: "",
        type: "error"
    })

    useEffect(() => {
        const getPlc_automate_24vAux = async () => {
            let res = await getPlc_automate_24v_list()
            if (res?.data) {
                setPlcListt(res?.data)
            }
            setLoading(false)
        }
        getPlc_automate_24vAux()
    }, [])

    const labelsTableHeader = [
        'Estado diagrama',
        'Nombre ',
        'Fecha de última actualización',
        'Ver',
    ];

    //Estados
    let RUNNING = 'RUNNING'
    let PAUSE = 'PAUSE'
    let STOP = 'STOP'
    //Acciones
    let INICIADO = 'INICIADO'
    let PAUSADO = 'PAUSADO'
    let DETENIDO = 'DETENIDO'

    const handleStateDiagramButton = async (id_automate, action) => {
        try {
            let flag = 0
            if (action === INICIADO) {
                flag = 0
            }
            if (action === DETENIDO) {
                flag = 1
            }
            if (action === PAUSADO) {
                flag = 2
            }
            let body = {
                'state': flag,
                'command': 'SET'
            }
            await putPlc_automate_24v_state_action(id_automate, body)

            let newPlcList = [...plcList].map((element) => {
                if (element?.id === id_automate) {
                    if (action === INICIADO) {
                        element.estado = RUNNING
                    }
                    if (action === DETENIDO) {
                        element.estado = STOP
                    }
                    if (action === PAUSADO) {
                        element.estado = PAUSE
                    }
                    return element
                }
                else {
                    return element
                }
            })
            setPlcListt(newPlcList)
        }
        catch (error) {
            let msg = ''
            if (error?.response?.data?.msg) {
                msg = `${error?.response?.data?.msg}.`
            }
            else {
                msg = `Ocurrió un error inesperado`
            }
            setMsg({
                msg: msg,
                type: "error"
            })
        }
        setClose(false)
    }

    const handleDirectionDiagram = async idAux => {
        history.push(`/plc/${idAux}`);
    };

    return (
        <>
            <TopNav titulo="PLC">
            </TopNav>

            {/* ALERTAS */}
            <AlertComponent
                severity={msg.type}
                msg={msg.msg}
                setClose={setClose}
                close={close}
            />

            <Container>
                {loading ?
                    <Box display="flex" justifyContent="center" marginTop={5}>
                        <CircularProgress />
                    </Box>
                    :
                    <>
                        {plcList?.length === 0 ?
                            <Alert severity="warning">
                                {'No posee ningun Automate vinculado a su cuenta.'}
                            </Alert>
                            :
                            <AlertPlcTable
                                plcComponent={true}
                                setPlcListt={setPlcListt}
                                dataList={plcList}
                                labelsTableHeader={labelsTableHeader}
                                setIsDeleteDialogOpen={() => () => { }}
                                handleStateDiagramButton={handleStateDiagramButton}
                                handleDirectionDiagram={handleDirectionDiagram}
                            />
                        }
                    </>
                }
            </Container>
        </>
    );
};
export default PLC_AUTOMATE_24V;
