import React, { useEffect, useState } from 'react';
// Material UI
import {
  Box,
  Button,
  Dialog,
  DialogTitle,
  makeStyles,
  Chip,
  CircularProgress,
  Stepper,
  Step,
  StepLabel,
  Tooltip,
  TextField,
} from '@material-ui/core';
import DoneIcon from '@material-ui/icons/Done';
//Icons
import TelegramIcon from '@mui/icons-material/Telegram';
import FileCopyIcon from '@mui/icons-material/FileCopy';
// Servicios
import {
  getUserBotList,
  addUserBotList,
  removeUserBotList,
} from '../../services/telegram';
import { checkTestEnvironment } from '../../helpers/environment';
import clsx from 'clsx';
import ConfirmationDialog from '../AlertsAndReports/ConfirmationDialog';

const useStyles = makeStyles(theme => ({
  root: {
    marginBottom: theme.spacing(1),
  },
  devicesLabel: {
    color: 'rgba(0, 0, 0, 0.54)',
    fontSize: '0.75rem',
    marginLeft: theme.spacing(1),
  },
  devicesBtn: {
    margin: theme.spacing(3, 1),
  },
  addBtn: {
    margin: theme.spacing(0.5),
    marginLeft: theme.spacing(1),
    background: theme.palette.primary.main,
    color: theme.palette.common.white,
    height: theme.spacing(3),
    width: theme.spacing(3),
    '&:hover': {
      backgroundColor: 'rgb(54, 143, 193);',
    },
  },
  filterChip: {
    margin: theme.spacing(0.5),
    '@media (max-width: 700px)': {
      width: '250px',
    },
  },
  filterLabel: {
    marginLeft: theme.spacing(1),
    color: 'rgba(0, 0, 0, 0.54)',
  },
  leftSpacing: {
    marginLeft: theme.spacing(5),
  },
  hint: {
    labelDate: {
      fontSize: 16,
      fontWeight: 700,
      color: theme.palette.primary.main,
    },
    backgroundColor: 'rgba(0, 0, 0, 0.38)',
    color: theme.palette.common.white,
    height: theme.spacing(3),
    width: theme.spacing(3),
    borderRadius: '50%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    '&:hover': {
      backgroundColor: 'rgb(54, 143, 193);',
    },
  },
  tick: {
    backgroundColor: theme.palette.success.light,
  },
  wrapperDatepicker: {
    marginTop: theme.spacing(1),
    textAlign: 'center',
  },
  btnSpacing: {
    margin: theme.spacing(0, 1),
    marginBottom: '5px',
  },
  dialogTitle: {
    '@media (max-width: 700px)': {
      textAlign: 'center',
    },
  },
  dialogSubtitle: {
    marginLeft: theme.spacing(3),
    color: theme.palette.primary.main,
    '@media (max-width: 700px)': {
      display: 'none',
    },
  },
  selectedItemsContainer: {
    height: '35px',
  },
  circular: {
    display: 'flex',
    justifyContent: 'center',
    marginTop: '15px',
  },
  label: {
    fontSize: 16,
    fontWeight: 700,
    color: theme.palette.primary.main,
    marginLeft: theme.spacing(3),
  },
  switchLabel: {
    fontSize: '0.75rem',
  },
  rowSpace: {
    marginBottom: theme.spacing(2),
  },
  flexGap: {
    gap: theme.spacing(1),
  },
  formGrow: {
    flexGrow: 1,
  },
  steppersFilter: {
    '@media (max-width: 920px)': {
      display: 'none',
    },
    '&.MuiStep-horizontal': {
      paddingLeft: '0',
      paddingRight: '0',
    },
  },
  labelDate: {
    fontSize: 16,
    fontWeight: 700,
    color: theme.palette.primary.main,
  },
  pendingChip: {
    backgroundColor: '#ffe117',
  },
}));

function ModalTelegram() {
  const classes = useStyles();
  const [isTelegramModalOpen, setIsTelegramModalOpen] = useState(false);
  const [botSub, setBotSub] = useState({});
  const [loading, setLoading] = useState(true);
  const [loadingChip, setLoadingChip] = useState(false);
  const [UID, setUID] = useState('');
  const [activeStep, setActiveStep] = useState(0);
  const steps = ['Copie su codigo único', 'Redirijase a Telegram'];
  const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState(false);

  useEffect(() => {
    getInitialBots();
    // eslint-disable-next-line
  }, []);

  const getInitialBots = async () => {
    try {
      setLoadingChip(true);
      setLoading(true);
      let botsList = await getUserBotList();
      let findBot = botsList?.data?.bots?.find(e => e.link_activo === true);
      if (findBot) {
        // console.log('TIENE USUARIO ACTIVO PENDIENTE.')
        setBotSub(findBot);
        setUID(findBot.uid);
      } else {
        // console.log('NO TIENE USUARIO ACTIVO PENDIENTE.')
      }
    } catch (error) {}

    setLoading(false);
    setLoadingChip(false);
  };

  const getUserBotListAux = async () => {
    try {
      setLoading(true);
      let botsList = await getUserBotList();
      let findBot = botsList?.data?.bots?.find(e => e.link_activo === true);
      if (findBot) {
        // console.log('TIENE USUARIO ACTIVO PENDIENTE.')
        setBotSub(findBot);
        setUID(findBot.uid);
      } else {
        // console.log('CONSULTAR UID NUEVA PARA NUEVO USUARIO.')
        await getUid();
      }
    } catch (error) {}
    setLoading(false);
  };

  const getUid = async () => {
    try {
      let res = await addUserBotList();
      setUID(res?.data?.uid);
      // console.log('CREA UNA NUEVA UID.')
      getInitialBots();
    } catch (error) {
      // console.log('YA TIENE UN BOT PENDIENTE.')
    }
  };

  const handleOpenClose = () => {
    setIsTelegramModalOpen(!isTelegramModalOpen);
    if (!isTelegramModalOpen) {
      getUserBotListAux();
    }
  };

  const getLinkLoginTelegram = () => {
    return `/login ${UID}`;
  };

  const redirectToTelegram = () => {
    window.open(returnUrlTelegram(), '_blank');
  };

  const returnUrlTelegram = () => {
    if (checkTestEnvironment()) {
      return 'https://t.me/powermate_notis_bot';
    } else {
      return 'https://t.me/PowermateBot';
    }
  };

  const handleDialogClose = () => {
    getInitialBots();
    getUserBotListAux();
    setIsTelegramModalOpen(false);
  };

  const handleDelete = async () => {
    try {
      setLoadingChip(true);
      await removeUserBotList();
      getInitialBots();
      getUserBotListAux();
      setLoadingChip(false);
      setIsDeleteDialogOpen(false);
    } catch (error) {
      setLoadingChip(false);
      setIsDeleteDialogOpen(false);
    }
  };

  const seeTelegramUuid = (botSub) => {
    if(botSub && !botSub.username){
        setUID(botSub.uid);
        setIsTelegramModalOpen(!isTelegramModalOpen);
    }
  };

  return (
    <>
      <Box mb={2}>
        <Box
          color="primary.main"
          display="flex"
          alignItems="center"
          mb={1}
          style={{ fontWeight: 'bold' }}
        >
          {'Telegram'}
        </Box>

        {loadingChip ? (
          <Box>
            <CircularProgress />
          </Box>
        ) : (
          <Box>
            {!botSub.username && (
              <Button variant="outlined" onClick={handleOpenClose}>
                Agregar cuenta telegram
              </Button>
            )}

            <Box mt={1} mb={2}>
              {Object.keys(botSub).length > 0 && (
                <Chip
                  icon={<TelegramIcon style={{ fontSize: '20px' }} />}
                  color="primary"
                  label={botSub.username ? botSub.username : 'Pendiente...'}
                  className={`${classes.filterChip} ${
                    !botSub.username ? classes.pendingChip : ''
                  }`}
                  onDelete={
                    botSub.username
                      ? () => setIsDeleteDialogOpen(true)
                      : undefined
                  }
                  onClick={() => seeTelegramUuid(botSub)}
                />
              )}
            </Box>
          </Box>
        )}
      </Box>

      <Dialog
        open={isTelegramModalOpen}
        aria-labelledby="form-dialog-title"
        onClose={handleOpenClose}
        maxWidth="md"
        fullWidth
        disableScrollLock
      >
        <Box className={classes.root}>
          {loading ? (
            <Box className={classes.circular}>
              <CircularProgress />
            </Box>
          ) : (
            <Box>
              <DialogTitle
                id="form-dialog-title"
                className={classes.dialogTitle}
              >
                {`Telegram`}
              </DialogTitle>

              <Stepper
                className={classes.steppersFilter}
                activeStep={activeStep}
                alternativeLabel
              >
                {steps.map(label => (
                  <Step className={classes.steppersFilter} key={label}>
                    <StepLabel>{label}</StepLabel>
                  </Step>
                ))}
              </Stepper>

              <Box display="flex" alignItems="center" minHeight={40} mb={2}>
                <Box
                  className={clsx(
                    classes.hint,
                    classes.leftSpacing,
                    {
                      [classes.active]: activeStep === 0,
                    },
                    {
                      [classes.tick]: activeStep > 0,
                    }
                  )}
                >
                  {activeStep >= 1 ? (
                    <DoneIcon style={{ padding: '2px' }} />
                  ) : (
                    1
                  )}
                </Box>

                <Box
                  display={'flex'}
                  style={{ marginLeft: '5px', width: '90%' }}
                >
                  <TextField
                    placeholder={'value'}
                    type="text"
                    fullWidth
                    label="Copie la siguiente linea"
                    name="dashboard_name"
                    size="small"
                    variant="outlined"
                    color="primary"
                    value={getLinkLoginTelegram()}
                    disabled={true}
                  />
                  <Tooltip title="Copiar texto" arrow placement="right">
                    <Button
                      style={{ marginLeft: '10px', marginRight: '10px' }}
                      size="small"
                      variant="contained"
                      onClick={() => {
                        navigator.clipboard.writeText(getLinkLoginTelegram());
                        setActiveStep(1);
                      }}
                    >
                      <FileCopyIcon
                        style={{ marginRight: '2px', fontSize: '17px' }}
                      />
                    </Button>
                  </Tooltip>
                </Box>
              </Box>

              <Box
                display="flex"
                alignItems="center"
                minHeight={40}
                mb={2}
                height={50}
              >
                <Box
                  className={clsx(
                    classes.hint,
                    classes.leftSpacing,
                    {
                      [classes.active]: activeStep === 1,
                    },
                    {
                      [classes.tick]: activeStep > 1,
                    }
                  )}
                >
                  {activeStep >= 2 ? (
                    <DoneIcon style={{ padding: '2px' }} />
                  ) : (
                    2
                  )}
                </Box>

                <Box
                  display={'flex'}
                  style={{ marginLeft: '5px', width: '90%' }}
                >
                  <Box display={'flex'} alignItems={'center'}>
                    <label className={classes.filterLabel}>
                      Dirijase a Telegram
                    </label>
                  </Box>

                  <Tooltip title={returnUrlTelegram()} arrow placement="right">
                    <Button
                      style={{ marginLeft: '15px', height: '35px' }}
                      size="small"
                      variant="contained"
                      onClick={() => {
                        redirectToTelegram();
                        setActiveStep(2);
                      }}
                    >
                      <TelegramIcon style={{ fontSize: '17px' }} />
                    </Button>
                  </Tooltip>
                </Box>
              </Box>

              <Box display="flex" justifyContent="center" alignItems="center">
                <Button
                  variant="contained"
                  onClick={handleDialogClose}
                  className={classes.btnSpacing}
                >
                  Aceptar
                </Button>
              </Box>
            </Box>
          )}
        </Box>
      </Dialog>

      <ConfirmationDialog
        isOpen={isDeleteDialogOpen}
        handleConfirm={handleDelete}
        handleCancel={() => setIsDeleteDialogOpen(false)}
        title={`¿Desea eliminar el usuario vinculado?`}
        content="En caso de confirmar el usuario será desvinculado"
      />
    </>
  );
}

export default ModalTelegram;
