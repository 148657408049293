
import { useState } from 'react';
import {
    Box, makeStyles, FormControl, Select, MenuItem
} from '@material-ui/core';
//Helpers.
import { useReactFlow, useNodes } from 'reactflow';
import { returnTextsBlock } from './helperDescriptionText';
import { blueBar } from '../../../helpers/common'
import { getSettingBlock } from '../helpers';
const useStyles = makeStyles(theme => ({
    leftSpacing: {
        marginLeft: theme.spacing(1),
    },
    hint: {
        backgroundColor: 'rgba(0, 0, 0, 0.38)',
        color: theme.palette.common.white,
        height: theme.spacing(3.1),
        width: theme.spacing(3.1),
        borderRadius: '50%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        marginRight: theme.spacing(1),
        '&:hover': {
            backgroundColor: 'rgb(54, 143, 193);',
        },
    },
    tick: {
        backgroundColor: theme.palette.success.light,
    },
    devicesLabel: {
        color: 'rgba(0, 0, 0, 0.54)',
        fontSize: '0.75rem',
    },
    title: {
        padding: theme.spacing(1),
        fontSize: 14,
        color: theme.palette.primary.dark,
    },
    content: {
        fontSize: 14,
        color: '#5c5d5d',
    }
}));

const InputsFilterOptions = ({ id, setHandleSourceQuantity, handleSourceQuantity, typeBlock, setDisabledButtonSave, disableEditing }) => {
    const blueLine = blueBar()
    const classes = useStyles();
    const reactFlow = useReactFlow();
    const nodes = useNodes()
    //Estado de el filtro.
    const [stateForm, setStateForm] = useState({
        quantity: handleSourceQuantity[handleSourceQuantity.length - 1],
    });

    const handleChange = (e) => {
        const { name, value } = e.target;
        if (value) {
            setDisabledButtonSave(false)
        }
        setStateForm(prevState => ({
            ...prevState,
            [name]: value
        }));
        //Devuelve un array segun el value ejemplo: value=4 [1,2,3,4]
        const elements = Array.from({ length: parseInt(value) }, (_, index) => index + 1);
        setHandleSourceQuantity(elements);
        let arryEdited = [...nodes].map((elem) => {
            if (elem.id === id) {
                elem.inputsQuantity = elements
            }
            return elem
        })
        reactFlow.setNodes(arryEdited)

    };

    return (
        <Box>

            {/* TITULO */}
            <Box m={1} >
                <h3 style={{ fontSize: '18px' }}>{`${getSettingBlock(typeBlock)?.name}`}</h3>
            </Box >

            {blueLine}

            <Box mb={2} >
                <Box className={classes.title}>
                    Descripcion:
                </Box>
                <Box className={classes.content} ml={1}>
                    {returnTextsBlock(typeBlock).description}
                </Box>
            </Box>
            {
                returnTextsBlock(typeBlock).implementation?.length > 1 &&
                <Box style={{ borderTop: `solid 1px ${'#a19292de'}` }} mt={1} mb={2} >
                    <Box className={classes.title}>
                        implementation:
                    </Box>
                    <Box className={classes.content} ml={1} >
                        {returnTextsBlock(typeBlock).implementation}
                    </Box>
                </Box>
            }

            {blueLine}

            <Box ml={1} >
                {/* STEP 2 */}
                <Box display={'flex'}>
                    <Box width={'60%'} >
                        <Box className={classes.devicesLabel}>
                            Cantidad de entradas.
                        </Box>
                        <FormControl fullWidth>
                            {/* <InputLabel id="variableLabel">Seleccione Variable</InputLabel> */}
                            <Select
                                disabled={disableEditing}
                                name='quantity'
                                value={stateForm.quantity}
                                onChange={handleChange}
                            >
                                {[2, 3, 4, 5].map((elem, indx) => (
                                    <MenuItem
                                        key={indx}
                                        value={elem}
                                    >
                                        {elem}
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    </Box>
                </Box>

            </Box>
        </Box >
    )
}
export default InputsFilterOptions