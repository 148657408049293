import { useContext, useEffect, useState } from 'react';
import { Handle, Position } from 'reactflow';
import { Box, makeStyles, Tooltip } from '@material-ui/core';
import { useNodes } from 'reactflow';
//Hook personalizado.
import DiagramContext from '../Store/DiagramContext';
//Componentes.
import ModalSettings from '../ModalSettings';
// //Helper
// import { getSettingBlock } from '../helpers';
//React flow
import { useEdges } from 'reactflow';

let styleAux = {
    width: `85px`,
    height: `85px`,
}

const useStyles = makeStyles(theme => ({
    root: {
        ...styleAux,
        backgroundColor: '#f5f5f5',
        border: `1px solid ${`black`}`,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    disabledStyle: {
        ...styleAux,
        pointerEvents: 'none',
        opacity: 0.5,
        color: '#a0a0a0',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    }
}));

function ContainerBlockGenericTypeLogic({ id, isConnectable, type, selected, userConfiguration, borderRadius, labelBlock }) {
    const classes = useStyles()
    const edges = useEdges();
    const { stateDiagram, } = useContext(DiagramContext)
    const nodes = useNodes()
    const [openModal, setOpenModal] = useState(false)
    const [handleSourceQuantity, setHandleSourceQuantity] = useState([1, 2])
    const [disabledNode, setDisabledNode] = useState(false);
    const [errorNode, setErrorNode] = useState(false);

    //Deshabilita el nodo segun el nodo el cual se esta conectando.
    useEffect(() => {
        let res = stateDiagram?.disabledNodes?.includes(type)
        setDisabledNode(res)
        // eslint-disable-next-line
    }, [stateDiagram?.disabledNodes])

    // Cambia el bloque a modo error (En el bloque hay un error de configuración)
    useEffect(() => {
        let res = stateDiagram?.errorBlockNodes?.includes(id)
        setErrorNode(res)
        // eslint-disable-next-line
    }, [stateDiagram?.errorBlockNodes])

    //Carga la cantidad de entradas en el casio de q existan.
    useEffect(() => {
        let nodeData = nodes.find(e => e.id === id)
        if (nodeData?.inputsQuantity) {
            setHandleSourceQuantity(nodeData?.inputsQuantity)
        }
        // eslint-disable-next-line
    }, [])


    const handleOpenModal = () => {
        setOpenModal(!openModal)
    }

    //Aparece o desaparece una entrada ya q solamente se pueden desaparecer con css.
    const visibilityHand = (number) => {
        return (handleSourceQuantity[handleSourceQuantity?.length - 1] >= number) ? 'visible' : 'hidden'
    }

    const checkTargetConnect = (idAux, elementAux) => {
        let flag = true
        for (let i = 0; i < edges.length; i++) {
            const element = edges[i];
            if (element.target === idAux) {
                if (element.targetHandle === elementAux.key) {
                    flag = false
                }
            }
        }
        return flag
    }

    return (
        <Box>

            <Box fontSize={6} display={'flex'} justifyContent={'center'}>
                {`${type}`}
            </Box>

            <Box
                className={disabledNode ? classes.disabledStyle : classes.root}
                key={id}
                style={{
                    border: selected ? `1px dashed ${errorNode ? '#f8000075' : '#2586bc'}` : `1px solid ${errorNode ? '#f8000075' : 'black'}`,
                    backgroundColor: errorNode ? 'rgba(248, 0, 0, 0.1)' : '',
                    borderBottomRightRadius: borderRadius ? '0px' : '10px',
                    borderTopRightRadius: borderRadius ? '0px' : '10px',
                }}
                onDoubleClick={handleOpenModal}
            >

                {openModal &&
                    <ModalSettings
                        handleOpenModal={handleOpenModal}
                        openModal={openModal}
                        setHandleSourceQuantity={setHandleSourceQuantity}
                        handleSourceQuantity={handleSourceQuantity}
                        id={id}
                        type={type}
                        userConfiguration={userConfiguration}
                    />
                }

                <Box display={'flex'}>
                    {labelBlock ?
                        <Box fontSize={11}  >
                            {labelBlock}
                        </Box>
                        :
                        <Box display={'flex'}>
                            <img src={`/images/diagram/${type.toLowerCase()}.svg`} alt="" width="35" height="35" />
                        </Box>
                    }
                </Box>

                <Tooltip
                    title={'Salida'}
                    arrow
                    placement='right'
                >
                    <Handle
                        type="source"
                        position={Position.Right}
                        isConnectable={isConnectable}
                        id="1"
                        style={{ top: 37, width: 8, height: 8, backgroundColor: '#1d1c33d1' }}
                    />
                </Tooltip>

                {
                    [1, 2, 3, 4, 5].map((element, index) => {
                        let top = 47
                        if (element === 2) {
                            top = 27
                        }
                        if (element === 3) {
                            top = 37
                        }
                        if (element === 4) {
                            top = 17
                        }
                        if (element === 5) {
                            top = 57
                        }
                        return (
                            <Box key={index}>
                                <Tooltip
                                    title={'Entrada'}
                                    arrow
                                    placement='right'
                                >
                                    <Handle
                                        key={element}
                                        type="target"
                                        position={Position.Left}
                                        id={element.toString()}
                                        style={{
                                            visibility: visibilityHand(element),
                                            top: top, width: 8, height: 8,
                                            border: `1px solid ${`black`}`,
                                            backgroundColor: checkTargetConnect(id, { key: element.toString() })
                                                ? 'white' : '#b7b4b4'
                                        }}
                                        isConnectable={handleSourceQuantity.length >= element ? (disabledNode ? false : isConnectable) : false}
                                    />
                                </Tooltip>


                                <Box style={{
                                    visibility: visibilityHand(element),
                                    position: 'absolute',
                                    top: top - 5,
                                    left: '7px',
                                    fontSize: '8px',
                                    width: '10px',
                                }}>
                                    {'o'}
                                </Box>

                            </Box>
                        )
                    })
                }
            </Box>
        </Box>
    );
}
export default ContainerBlockGenericTypeLogic;