import instance from './testing.instance';

export const getDiagramAlert = () => instance.get(`/alerts/`);
export const getIndividualDiagramAlert = (id) => instance.get(`/alerts/${id}/`);
export const postSaveDiagramAlert = (body) => instance.post(`/alerts/`, body);
export const putUpdateDiagramAlert = (id, body) => instance.put(`/alerts/${id}/`, body);
export const deleteDiagramAlert = (id) => instance.delete(`/alerts/${id}`);
export const alertRecords = (id, page = 1) => instance.get(`/alerts/${id}/history/?page=${page}`)
//Diagramas Free
export const getDiagramList = () => instance.get(`/diagram-free/`);
export const getDiagramByid = (diagramId) => instance.get(`/diagram-free/${diagramId}/`);
export const createDiagram = (body) => instance.post(`/diagram-free/`, body);
export const editDiagram = (diagramId, body) => instance.put(`/diagram-free/${diagramId}/`, body);
export const deleteDiagram = (diagramId) => instance.delete(`/diagram-free/${diagramId}/`);
//Descarga archivo json o txt diagrama free
export const getExportDiagram = (id_diagram) => instance.get(`/diagram-free/${id_diagram}/export-diagram-plc/`);
//Descarga archivo plc diagrama free
export const getExportDiagramPlcFormat = (id_diagram) => instance.get(`/diagram-free/${id_diagram}/export-translation-diagram/`);



