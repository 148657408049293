import ContainerBlockGenericType from "../ContainersBlocks/ContainerBlockGenericType";

function VARIABLE
    ({ id, isConnectable, type, selected }) {
    return (
        <ContainerBlockGenericType
            id={id}
            isConnectable={isConnectable}
            type={type}
            selected={selected}
            flagNumber={42}
            labelBlock={'VAR'}
            userConfiguration={true}
        />
    );
}
export default VARIABLE