import React, { useCallback, useEffect } from 'react';
import {
    Box,
} from '@material-ui/core';
import ReactFlow, {
    // useNodesState,
    // useEdgesState,
    Background,
    BackgroundVariant,
    MiniMap,
    // useStoreApi,
    // useOnSelectionChange,
    useReactFlow,
    ReactFlowProvider,
    Controls,
} from 'reactflow';
import Sidebar from './Sidebar';
import 'reactflow/dist/style.css';
import './Styles/root.css';
//Models.
import AND from './Models/AND.js'
import EQ from './Models/EQ.js'
import VARIABLE from './Models/VARIABLE.js'
import GEQ from './Models/GEQ.js';
import GREATER from './Models/GREATER.js';
import ALERT from './Models/ALERT.js';
import NOT from './Models/NOT.js';
import OR from './Models/OR.js';
import LEQ from './Models/LEQ.js';
import LESS from './Models/LESS.js';
import CONST from './Models/CONST.js';
import ADDER from './Models/ADDER.js';
//PLC
import OUTD from './Models/OUTD.js';
import OUTA from './Models/OUTA.js';
import TCRON from './Models/TCRON.js';
import INA from './Models/INA.js';
import IND from './Models/IND.js';
import DELAY from './Models/DELAY.js';
import MUX4 from './Models/MUX4.js';
import MUX2 from './Models/MUX2.js';
import FFRS from './Models/FFRS.js';
import CAST from './Models/CAST.js';
import REDGE from './Models/REDGE.js';
import FEDGE from './Models/FEDGE.js';
import TLOC from './Models/TLOC.js';
import TUTC from './Models/TUTC.js';
import MQTTP from './Models/MQTTP.js';
import MBS from './Models/MBS.js';
import MBC from './Models/MBC.js';
import TRET from './Models/TRET.js';
import TPUL from './Models/TPUL.js';
import TOFF from './Models/TOFF.js';
import TON from './Models/TON.js';
import THOLD from './Models/THOLD.js';
import COUNT from './Models/COUNT.js';
import LPF from './Models/LPF.js';
import AVG from './Models/AVG.js';
import MIN from './Models/MIN.js';
import MAX from './Models/MAX.js';
import GAIN from './Models/GAIN.js';
import MOD from './Models/MOD.js';
import DIV from './Models/DIV.js';
import MUL from './Models/MUL.js';
import NAND from './Models/NAND.js';
import XNOR from './Models/XNOR.js';
import NOR from './Models/NOR.js';
import XOR from './Models/XOR.js';
import MAVG from './Models/MAVG.js';
import PULSEGEN from './Models/PULSEGEN.js';
import PWM from './Models/PWM.js';
import SHOLD from './Models/SHOLD.js';
import RAND from './Models/RAND.js';
import MBR from './Models/MBR.js';
import VAUP from './Models/VAUP.js';
import EVUP from './Models/EVUP.js';
import EVUPD from './Models/EVUP.js';
import EVUPA from './Models/EVUPA.js';
import EVDO from './Models/EVDO.js';
import EVDOD from './Models/EVDOD.js';
import EVDOA from './Models/EVDOA.js';
//Estilos minimap
import './Styles/minimap.css';
// Custom Hooks
import useDeviceDetect from "../../hooks/useDeviceDetect";

const nodeTypes = {
    EVDOA: EVDOA,
    EVDOD: EVDOD,
    EVDO: EVDO,
    EVUPA: EVUPA,
    EVUPD: EVUPD,
    EVUP: EVUP,
    VAUP: VAUP,
    MBR: MBR,
    RAND: RAND,
    SHOLD: SHOLD,
    PWM: PWM,
    PULSEGEN: PULSEGEN,
    MAVG: MAVG,
    XOR: XOR,
    NOR: NOR,
    XNOR: XNOR,
    NAND: NAND,
    MUL: MUL,
    DIV: DIV,
    MOD: MOD,
    GAIN: GAIN,
    MAX: MAX,
    MIN: MIN,
    AVG: AVG,
    LPF: LPF,
    COUNT: COUNT,
    THOLD: THOLD,
    TON: TON,
    TOFF: TOFF,
    TPUL: TPUL,
    TRET: TRET,
    MBC: MBC,
    MBS: MBS,
    MQTTP: MQTTP,
    FEDGE: FEDGE,
    TUTC: TUTC,
    TLOC: TLOC,
    REDGE: REDGE,
    CAST: CAST,
    FFRS: FFRS,
    MUX2: MUX2,
    MUX4: MUX4,
    DELAY: DELAY,
    IND: IND,
    INA: INA,
    TCRON: TCRON,
    OUTA: OUTA,
    OUTD: OUTD,
    ALERT: ALERT,
    AND: AND,
    EQ: EQ,
    VARIABLE: VARIABLE,
    GEQ: GEQ,
    LEQ: LEQ,
    GREATER: GREATER,
    LESS: LESS,
    NOT: NOT,
    OR: OR,
    CONST: CONST,
    ADDER: ADDER
};

const Flow = ({
    nodes, edges, onNodesChange, onEdgesChange, onSelectionDrag,
    setReactFlowInstance, onConnect, onConnectEnd, onDrop, onConnectStart, resetZoom, componentType
}) => {
    const proOptions = { hideAttribution: true }
    const { isMobile } = useDeviceDetect(930);
    const reactFlow = useReactFlow();
    const onDragOver = useCallback((event) => {
        event.preventDefault();
        event.dataTransfer.dropEffect = 'move';
    }, []);

    //Coloca el zoom en cero cuando importo un diagrama nuevo.
    useEffect(() => {
        reactFlow.zoomTo(0.5)
        // eslint-disable-next-line
    }, [resetZoom])

    return (
        <ReactFlow
            fitView
            selectionOnDrag={true} // Permite la selección al arrastrar        
            panOnDrag={isMobile ? true : [1, 2]} // el 1 habilita el click scroll de el mause para q no bloquee la pantalla.
            // panOnDrag={true}
            // paneMoveable={true}
            zoomOnPinch={false}

            nodesDraggable={componentType !== 'Automate-24V-4M' ? true : false}        // Desactiva el arrastre de nodos
            zoomOnDoubleClick={false}

            // panOnScrollSpeed={3}
            // onElementsRemove={onElementsRemove}
            //Habilita el tipo de movimiento q podes realizar con el evento mover.
            //Activa mover la pantalla con el click de la rueda de el raton.
            // panOnScroll={true}
            // panOnScrollMode={'free'}
            //Desactiva q muevas con el click de el raton.
            // paneMoveable={false}
            deleteKeyCode={["Delete"]}
            // multiSelectionKeyCode={null} // Deshabilita la selección múltiple con teclas
            // selectionKeyCode={['Space']}   
            // zoomActivationKeyCode={['Meta'] }  
            onInit={setReactFlowInstance}
            onDrop={onDrop}
            onDragOver={onDragOver}
            proOptions={proOptions}
            nodes={nodes}
            edges={edges}
            onNodesChange={onNodesChange}
            onEdgesChange={onEdgesChange}
            onConnect={(params) => onConnect(params, nodes)}
            onConnectStart={(params) => onConnectStart(params, nodes)}
            onConnectEnd={onConnectEnd}
            nodeTypes={nodeTypes}
            onSelectionChange={onSelectionDrag}
        // zoomOnDoubleClick={true}
        // onEdgeClick={onEdgeClick}
        // onNodeDoubleClick={() => { console.log('dobleclick') }}
        // onNodeClick={() => console.log('un solo click')}      
        >
            <Controls />
            <Background
                variant={BackgroundVariant.Cross}
                gap={10}
            />
            {
                !isMobile &&
                <MiniMap
                    className="custom-minimap"
                    // nodeComponent={MiniMapNode}
                    nodeStrokeWidth={5}
                    zoomable={true}
                    pannable={true}
                    zoomStep={2}
                    offsetScale={5}
                />
            }
        </ReactFlow >
    );
};

const Root = ({
    nodes, onNodesChange, edges, onEdgesChange, onSelectionDrag, setReactFlowInstance,
    componentType, onConnect, onConnectEnd, onDrop, onConnectStart, isMobile, resetZoom
}) => (
    <Box className="dndflow">
        <ReactFlowProvider>
            {/* Drag and drop */}
            {
                !isMobile && componentType !== 'Automate-24V-4M' &&
                < Sidebar componentType={componentType} />
            }
            {/* Componente flow recursivo */}
            <Flow
                componentType={componentType}
                resetZoom={resetZoom}
                onSelectionDrag={onSelectionDrag}
                edges={edges}
                nodes={nodes}
                onNodesChange={onNodesChange}
                onEdgesChange={onEdgesChange}
                onConnect={onConnect}
                onConnectEnd={onConnectEnd}
                onConnectStart={onConnectStart}
                onDrop={onDrop}
                setReactFlowInstance={setReactFlowInstance}
            />
        </ReactFlowProvider>
    </Box>
);
export default Root